import IButtonShared from "../../Interfaces/buttonShared"
import styles from "./ButtonShared.module.css"


const InitButtonSharedLogic = (props: IButtonShared) =>{
    const getImgClass = () =>{
        if(props.img_size === "large") return styles.submit__icon
        if(props.img_side === "left") return styles.left__icon
    
        return styles.right__icon
      }

      return {getImgClass}
}

export default InitButtonSharedLogic