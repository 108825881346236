import IWallProfile from "../../interfaces/wallProfile"
import { clientInstance } from "./clientInstance"


const useWallProfileApiService = () => {
    const client = clientInstance();
    
    const getWallProfilesByPage = (page: number, rowsPerPage: number) => 
        client.get<any>(`/wallProfiles/get?page=${page}&size=${rowsPerPage}&sort=name,asc`)

    const getWallProfiles = () =>
        client.get(`/wallProfiles/get`)

    const getWallProfileById = (id: number) => 
        client.get(`/wallProfiles/get/${id}`)
    
    const updateWallProfile = (formValue: any) =>
        client.post(`/wallProfiles/update`, formValue)

    const createWallProfile = (formValue: IWallProfile) => 
        client.post(`/wallProfiles/create`, formValue)

    const deleteWallProfileRequest = (id: number) => 
        client.delete<any>(`/wallProfiles/delete/${id}`)

    return {getWallProfiles, getWallProfileById, updateWallProfile, createWallProfile, getWallProfilesByPage, deleteWallProfileRequest}
}

export default useWallProfileApiService