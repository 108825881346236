import ISwitchButton from "../../Interfaces/switchButton";
import InitSwitchButtonLogic from "./switchButton.logic";
import styles from "./SwitchButton.module.css";

const SwitchButton = (props: ISwitchButton) => {
    const {activeButton, activateButton} = InitSwitchButtonLogic(props)

  return (
    <>
    <label className={`${styles.label} ${styles.ml10}`} htmlFor="centerDistance">{props.title} {props.required && <span aria-hidden="true">*</span>}</label>
      <div className={`${styles.switch}`}>
        <div
          onClick={() => {
            props.onClick(props.argumentLeft);
            activateButton("left");
          }}
          className={`${styles.switch__item} ${
            activeButton === "left" ? styles.active : ""
          }`}
        >
          {props.leftButtonText}
        </div>
        <div
          onClick={() => {
            props.onClick(props.argumentRight);
            activateButton("right");
          }}
          className={`${styles.switch__item} ${
            activeButton === "right" ? styles.active : ""
          }`}
        >
          {props.rightButtonText}
        </div>
      </div>
    </>
  );
};

export default SwitchButton;
