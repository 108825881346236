import React from "react";
import styles from "./Orders.module.css";
import InitOrdersComponentLogic from "./Orders.logic";
import LoadingComponent from "../Loading/Loading.component";
import InfiniteScroll from "react-infinite-scroller";
import { t } from "i18next";
import { Link } from "react-router-dom";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import arrowLeft from "../../../assets/images/wave3/arrow-left.svg";
import { OrderStatus } from "../../../enums/orderStatus";
import { OrderCategory } from "../../../enums/orderCategory";

const OrdersComponent: React.FC = () => {
  const {
    orders,
    loadingOrders,
    next,
    scrollParentRef,
    contentHeight,
    loadMore,
    formatDate,
    orderCategory,
  } = InitOrdersComponentLogic();

  return (
    <>
      {loadingOrders && (
        <div>
          <LoadingComponent />
        </div>
      )}
      {/* {!loadingOrders && orders === null && <h1>No order history</h1>} */}

      {!loadingOrders !== null && (
        <>
          {orderCategory === OrderCategory.HISTORY && (
            <h2 className={styles.title}>{t("Orders History")}</h2>
          )}

          {orderCategory === OrderCategory.PROCESSING && (
            <h2 className={styles.title}>{t("Projects in processing")}</h2>
          )}

          <div
            className={styles.orders}
            style={{ height: `${(contentHeight - 80) * 0.9}px` }}
            ref={scrollParentRef}
          >
            {orders !== null && scrollParentRef !== null && (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={next}
                useWindow={false}
                loader={
                  <div className="loader" key={0}>
                    {t("Loading ...")}
                  </div>
                }
                getScrollParent={() =>
                  scrollParentRef.current as unknown as HTMLElement
                }
              >
                {orders?.map((orderItem, index) => (
                  <Link
                    key={index}
                    className={styles.order__link}
                    to={`${orderItem.id}`}
                  >
                    <div className={styles.order}>
                      <div className={styles.order__header}>
                        <span className={styles.order__date}>
                          {formatDate(orderItem.created)}
                        </span>
                        {orderItem.status === OrderStatus.PENDING && (
                          <span
                            className={`${styles.status} ${styles.status__DRAFT}`}
                          >
                            {t(orderItem.status)}
                          </span>
                        )}
                        {orderItem.status === OrderStatus.OFFER && (
                          <span
                            className={`${styles.status} ${styles.status__INPROGRESS}`}
                          >
                            {t(orderItem.status)}
                          </span>
                        )}

                        {orderItem.status === OrderStatus.ORDER && (
                          <span
                            className={`${styles.status} ${styles.status__ORDER}`}
                          >
                            {t(orderItem.status)}
                          </span>
                        )}
                        {orderItem.status === OrderStatus.EXECUTION && (
                          <span
                            className={`${styles.status} ${styles.status__EXECUTION}`}
                          >
                            {t(orderItem.status)}
                          </span>
                        )}
                        {orderItem.status === OrderStatus.REJECTED && (
                          <span
                            className={`${styles.status} ${styles.status__REJECTED}`}
                          >
                            {t("REJECTED")}
                          </span>
                        )}
                        {orderItem.status === OrderStatus.DONE && (
                          <span
                            className={`${styles.status} ${styles.status__DONE}`}
                          >
                            {t("DONE")}
                          </span>
                        )}
                      </div>
                      <h4 className={styles.order__id}>{orderItem.name}</h4>
                      <span className={styles.order__date}>
                        {orderItem.code}
                      </span>
                    </div>
                  </Link>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </>
      )}

      <div className={styles.bottom__buttons}>
        <Link className={styles.checkout__link} to={"/dashboard"}>
          <ButtonShared
            type="button"
            img_src={arrowLeft}
            img_side={"left"}
            text={t("Back to dashboard")}
          />
        </Link>
      </div>
    </>
  );
};

export default OrdersComponent;
