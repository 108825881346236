import IRoomOverview from "../../../Interfaces/roomOverview";

const NameOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <div>
            {params.name}
        </div>
    )
}

export default NameOverviewComponent;