import IPage from "../../../interfaces/page"
import NavigationComponent from "../../../Wave3/Components/Navigation/Navigation.component"
import globalStyles from "../../../styles/Global.module.css"
import loginStyles from '../../Login/Login.module.css';
import InputShared from "../../../Wave3/SharedComponents/InputShared/InputShared";
import { t } from "i18next";
import { Link } from "react-router-dom";
import InitCreateConfigurationLogic from "./CreateConfiguration.logic";
//eslint-disable-next-line
import ImageSelectionModal from "../../../Wave3/Components/ImageSelectionModal/ImageSelectionModal.component";
import LoadingComponent from "../../../Wave3/Components/Loading/Loading.component";

const CreateConfiguration: React.FunctionComponent<IPage>  = () => {
    //eslint-disable-next-line
    const {formValue, handleChange, handleSubmit, showSelectionModal, setShowSelectionModal, setFormValue, isLoading} = InitCreateConfigurationLogic()
    return (   
        <>
        {isLoading && <LoadingComponent />}
        {!isLoading && <>                <NavigationComponent />

        <div className={globalStyles.layout}>
            <div className={loginStyles.form__container}>
                <form className={loginStyles.form} onSubmit={handleSubmit}>
                    <div className={loginStyles.form__header}>
                        <h2 className={loginStyles.form__title}>{t("Create configuration")}</h2>
                        <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                    </div>

                    <InputShared name="configurationKey" labelText={t("Configuration key")} required={true} type="text" onChange={handleChange} value={formValue.configurationKey} />
                    <br/>
                    <InputShared name="value" labelText={t("Value")} required={true} type="text" onChange={handleChange} value={formValue.value}/>
                    

                    <div className={loginStyles.form__bottom}>
                        <Link to={`/configuration`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                        <button disabled={false} type="submit">{t("Save")}</button>
                    </div>
                </form>
            </div>
        </div>
        {/* {showSelectionModal && <ImageSelectionModal cancel={() => setShowSelectionModal(false)} select={(url: string) => {setFormValue({...formValue, value: url})}} selected={formValue.value}/>} */}  
        </>
        }
        </>
        )
}
export default CreateConfiguration