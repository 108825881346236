import { useEffect } from "react"
import ICookieModal from "../../Interfaces/cookieModal"
import Cookies from "universal-cookie"

const InitCookieModalLogic = (props: ICookieModal) => {
    const accept = () => {
        const today = new Date();
        const cookies = new Cookies()
        cookies.set("cookieConsent", true, {expires: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())})
        props.close()
        localStorage.setItem("cookies", "true")
    }
 
    return {accept}
}


export default InitCookieModalLogic