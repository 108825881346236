import { t } from 'i18next';
import React from 'react'
import styles from './Loading.module.css'

const LoadingComponent: React.FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t("Loading...")}</h2>
            <div className={styles.loading}>
                <div className={styles.progress}></div>
            </div>
        </div>
    )
}

export default LoadingComponent;