import { useEffect, useState } from "react";
import { Walkability } from "../../../../enums/walkability";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";

const InitCRStep5Logic = (params: IStep<IRoomFormData>) => {
    const {updateRoom} = useRoomApiService();
    const {resetBackButtonToDashboardState} = useBackButtonHelper()
    useEffect(() => {
        params.setDisableNext(false, 4);
        // if (searchParams.get('mode') === 'edit') {
        //     params.options?.setBackText(t("Or back to project overview"))
        // }
        // else if (searchParams.get('mode') === 'redirection') {
        //     params.options?.setBackText(t("Or back to projects"))
        // }
        // else {
        //     params.options?.setBackText(t("Or back to project"))
        // }        // eslint-disable-next-line 
        if(params.setShouldSetBackButton) params.setShouldSetBackButton(false)
    }, [params]);

    useEffect(() => {
        params.setDisableNext((params.data.walkability === Walkability.OTHER) && ((params.data.traficLoadsDescription?.length === 0) || (params.data.traficLoads?.toString().length === 0 || Number(params.data.traficLoads?.toString()) === 0) ), 4)
        //eslint-disable-next-line
    }, [params.data.walkability, params.data.traficLoads, params.data.traficLoadsDescription])
    
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        let newData = {...params.data, nextStep: (params.data.nextStep > 5 ? params.data.nextStep : 5)}
        updateRoom(newData, setIsLoading, params.options.setAllowSkipTo, params.setData);

        // eslint-disable-next-line 
    }, [])

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        if(Number(event.currentTarget.value) > 1000000000) event.currentTarget.value = '1000000000'
        if(Number(event.currentTarget.value) < 0) event.currentTarget.value = '0'
        
        let newData = dataHandlerHelper.handleInputChange(event, { ...params.data });
        if (newData.walkability !== Walkability.OTHER){
            newData.traficLoads = 0;
            newData.traficLoadsDescription = ""
        }
        params.options.setAllowSkipTo(5)
        params.setData({...newData, nextStep: 5});
        if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
    }
    return { onChange, isLoading };
}


export default InitCRStep5Logic;