import { t } from "i18next";
import UtilsHelper from "../../../../helpers/utils.helper";
import IOrder from "../../../Interfaces/order";
import InitAdminOrdersItemLogic from "./AdminOrdersItem.logic";
import styles from "./AdminOrdersItem.module.css";
import { OrderStatus } from "../../../../enums/orderStatus";

const AdminOrdersItemComponent: React.FunctionComponent<IOrder> = (
  order: IOrder
) => {
  const { handleClick } = InitAdminOrdersItemLogic(order);

  return (
    <>
      <div
        className={styles.order}
        onClick={() => {
          handleClick();
        }}
      >
        <div>
          <span className={styles.order__id}>{order.name} - {order.code}</span> -
          <span> {UtilsHelper.formatDate(order.created)}</span>
        </div>
        <div>
          <span>
            {order.user.firstName} {order.user?.lastName}
          </span>
          {order.status === OrderStatus.PENDING && (
            <span className={`${styles.status} ${styles.status__DRAFT}`}>
              {t("PENDING")}
            </span>
          )}
          {order.status === OrderStatus.OFFER && (
            <span className={`${styles.status} ${styles.status__INPROGRESS}`}>
              {t("OFFER")}
            </span>
          )}
          {order.status === OrderStatus.ORDER && (
            <span className={`${styles.status} ${styles.status__ORDER}`}>
              {t("ORDER")}
            </span>
          )}
          {order.status === OrderStatus.EXECUTION && (
            <span className={`${styles.status} ${styles.status__EXECUTION}`}>
              {t("EXECUTION")}
            </span>
          )}
          {order.status === OrderStatus.DONE && (
            <span className={`${styles.status} ${styles.status__DONE}`}>
              {t("DONE")}
            </span>
          )}
          {order.status === OrderStatus.REJECTED && (
            <span className={`${styles.status} ${styles.status__REJECTED}`}>
              {t("REJECTED")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminOrdersItemComponent;
