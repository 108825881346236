import i18next, { t } from 'i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import Config from '../../../config/config';
import InputShared from '../../SharedComponents/InputShared/InputShared';
import PasswordChecker from '../../SharedComponents/PasswordChecker/PasswordChecker';
import NotificationModalComponent from '../NotificationModal/NotificationModal.component';
import InitRegisterFormLogic from './RegisterForm.logic';
import styles from './RegisterForm.module.css';

const RegisterFormComponent: React.FC = () => {
    const { data, errorMessage, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange, onReCaptchaChange, reCaptcha, toggleShowPassword, showPassword, password, onPasswordChange } = InitRegisterFormLogic();
    const navigate = useNavigate()
    const handleClick = (e: any) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        navigate("/")
        // targetLink.href;
    }

    return (
        <>{data !== null &&
            <form onSubmit={onSubmit}>
                <h2 className={styles.title}>{t('Create account')}</h2>
                <p className={styles.description}><span>*</span> {t('indicates a required field')}</p>

                <div className={styles.input__group}>
                    <div>
                        <InputShared name="username" type='email' placeholder='name@yourdomain.com' value={data.username} required={true} onChange={onChange} labelText={t("Email address")} mode="settings" />
                    </div>
                    <div>
                        <InputShared name="phone" type="text" placeholder='123456' value={data.phone} required={true} onChange={onChange} labelText={t("Phone")} mode="settings" />
                    </div>
                </div>
                <div className={styles.input__group} style={{ marginBottom: "-20px" }}>
                    <div>
                        <InputShared name="password" labelText={t("Password")} required={true} onChange={onPasswordChange} type="password" placeholder='*******' value={data.password}
                            mode="step" pass_strong={false} showHide={true} toggleGroupShow={toggleShowPassword} showPasswordGroup={showPassword} />
                    </div>
                    <div>
                        <InputShared showPasswordGroup={showPassword} name="repeatPassword" type='password' placeholder='*******' value={data.repeatPassword} required={true} onChange={onChange} labelText={t('Repeat password')} mode="settings" />
                    </div>

                </div>
                <PasswordChecker password={password} />
                <div className={styles.input__group}>
                    <div>
                        <InputShared name="firstName" type="text" placeholder={t("First name")} value={data.firstName} required={true} onChange={onChange} labelText={t("First name")} mode="settings" />
                    </div>
                    <div>

                        <InputShared name="lastName" type="text" placeholder={t("Last name")} value={data.lastName} required={true} onChange={onChange} labelText={t("Last name")} mode="settings" />
                    </div>
                </div>
                <label className={styles.label}>{t("Gender")} <span>*</span></label>
                <div className={`${styles.input__gender__group} ${styles.input__group}`}>
                    <div>
                        <input type="radio" id="mr" name="genderTitle" value="mr" onChange={onChange} required /> <label htmlFor='mr'>{t("Mr.")}</label>
                    </div>
                    <div>
                        <input type="radio" id="mrs" name="genderTitle" value="mrs" onChange={onChange} /> <label htmlFor='mrs'>{t("Mrs.")}</label>
                    </div>
                </div>
                <InputShared name="address" type="text" placeholder={t("Address")} value={data.address} required={true} onChange={onChange} labelText={t("Address")} mode="settings" />
                <InputShared name="company" type="text" placeholder={t("Company")} value={data.company} onChange={onChange} labelText={t("Company")} mode="settings" />

                {errorMessage !== '' && <p className={styles.error__message}>{t(errorMessage)}</p>}

                <div className={styles.captcha}><ReCAPTCHA ref={reCaptcha}  sitekey={Config.defaults.reCaptchaSiteKey} onChange={onReCaptchaChange} hl={i18next.language} /></div>
                <div className={styles.terms}><input checked={data.acceptedGTPPD} onChange={onChange} id="terms" name='acceptedGTPPD' type="checkbox" required />
                    <label dangerouslySetInnerHTML={{
                        __html: t("I agree to the General Terms and Conditions, Privacy Policy and Disclaimer")
                            .replace("{gtUrl}", t("gtUrl"))
                            .replace("{ppUrl}", t("ppUrl"))
                            .replace("{dUrl}", t("dUrl")) + "<span> *</span>"           
                                 }}
                        className={styles.label} htmlFor="terms">

                    </label>
                </div>

                <button disabled={isSubmitDisabled || !data.acceptedGTPPD} type='submit' className={styles.button}>{t("Create account")}</button>
                <p className={styles.cta__paragraph} dangerouslySetInnerHTML={{ __html: t("Already a member? <a href=''>Sign in</a> instead") }} onClick={handleClick}></p>

            </form>}


            {presentNotificationModal && <NotificationModalComponent {...notificationModal}></NotificationModalComponent>}
        </>
    )
}

export default RegisterFormComponent;