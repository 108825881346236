import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IButtonShared from "../../Interfaces/buttonShared";
import InitButtonSharedLogic from "./ButtonShared.logic";
import styles from "./ButtonShared.module.css";

const ButtonShared = (props: IButtonShared) => {
  const {getImgClass} = InitButtonSharedLogic(props)

  return (
    <button
      onClick={() => props.onClick?.()}
      type={props.type}
      className={`${styles.commonButton} ${(props.wide ? styles.wideButton : styles.button)} ${(props.noText ? styles.no__text : "")}`}
      disabled={props.disabled}
    >
      {(props.img_side === "right" || !props.img_side) && props.text}
      
      {props.img_src && (
        <img
          className={
            `${getImgClass()} ${props.noText ? styles.img__no__text : ""}`
          }
          src={props.img_src}
          alt="img"
        />
      )}

      {props.img_fa && (
        <FontAwesomeIcon className={getImgClass()} icon={props.img_fa}></FontAwesomeIcon>
      )}

      {props.img_side === "left" && props.text}
    </button>
  );
};

export default ButtonShared;