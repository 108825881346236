import { t } from "i18next";
import IInputShared from "../../Interfaces/inputShared";
import styles from "./InputShared.module.css";
import stepStyles from "./StepInput.module.css";
import InputWrapper from "./InputWrapper";
import PasswordChecker from "../PasswordChecker/PasswordChecker";
import InitInputLogic from "./InputShared.logic";

const InputShared = (props: IInputShared) => {
  const {
    getInputClass,
    getLabelClass,
    password,
    evaluatePassword,
    getHideOrShow,
    shouldShowPass,
    toggleShowPassword,
    isError,
    errorMsg,
    setValue,
    checkForErrors,
    handlePaste,
  } = InitInputLogic(props);

  return (
    <div>
      {props.labelText !== "" && (
        <div className={stepStyles.label__container}>
          <label htmlFor={props.name} className={getLabelClass()}>
            {t(props.labelText)}{" "}
            {props.required && (
              <span
                aria-hidden="true"
                className={
                  props.mode === "step" ? stepStyles.span : styles.span
                }
              >
                *
              </span>
            )}
          </label>
        </div>
      )}

      <InputWrapper
        mode={props.mode}
        pass={props.type === "password" ? true : false}
        child={
          <>
            <input
              value={props.value}
              id={props.name}
              name={props.name}
              type={!shouldShowPass() ? props.type : "text"}
              required={props.required}
              onChange={
                !props.object
                  ? (e) => {
                      setValue(e.currentTarget.value);
                      checkForErrors(e.currentTarget.value);
                      props.onChange(e);
                      if (props.pass_strong) evaluatePassword(e);
                    }
                  : (e) => {
                      setValue(e.currentTarget.value);
                      checkForErrors(e.currentTarget.value);
                      props.onChange(e, props.object);
                    }
              }
              step={props.step}
              min={props.min}
              max={props.max}
              defaultValue={props.defaultValue}
              className={`${getInputClass()}`}
              style={{
                border: `${props.shouldHaveErrorBorder ? "1px solid red" : ""}`,
              }}
              placeholder={props.placeholder}
              onKeyDown={(e) =>
                props.type === "number" &&
                ["e", "E", "+", "-"].includes(e.key) &&
                e.preventDefault()
              }
              onPaste={(e) => props.type === "number" && handlePaste(e)}
            />
            {props.units && (
              <div className={`${styles.units} ${isError ? styles.error : ""}`}>
                {props.units}
              </div>
            )}
            {props.showHide && (
              <div
                className={styles.show__pass__units}
                onClick={
                  props.toggleGroupShow
                    ? () => props.toggleGroupShow?.()
                    : toggleShowPassword
                }
              >
                {
                  <label htmlFor="showPass" style={{ cursor: "pointer" }}>
                    {getHideOrShow()}
                  </label>
                }
              </div>
            )}
          </>
        }
      />
      {isError && <p className={styles.errorMsg}>{errorMsg}</p>}
      {props.pass_strong && <PasswordChecker password={password} />}
    </div>
  );
};

export default InputShared;
