import { OrderCategory } from "../../enums/orderCategory";
import { OrderStatus } from "../../enums/orderStatus";
import ICheckout from "../../interfaces/checkout";
import { clientInstance } from "./clientInstance";

const useOrderApiService = () => {
  const client = clientInstance();

  const getByStatus = (page: Number, status: string) =>
    client.get(`/order/admin/get`, {
      params: { page: page, sort: `created,desc` },
    });

  const uploadFile = (formData: FormData, orderId: number) =>
    client.post(`/order/admin/${orderId}/add-offer-files`, formData);

  const updateStatus = (id: Number, status: string) =>
    client.post(`/order/admin/status/`, { id: id, status: status });

  const getAll = (page: Number, orderType: OrderCategory) =>
    client.get(`/order/get`, {
      params: { page: page, sort: `created,desc`, orderCategory: orderType },
    });

  const getById = (id: Number) => client.get(`/order/get/${id}`);

  const submit = (data:ICheckout) =>
    client.post(`/order/submit`, data);

  const adminGetByStatus = (page: Number, status:OrderStatus|'', code:String) =>
    client.get(`/order/admin/get`, {
      params: { page: page, sort: `created,desc`, code: code, status:status},
    });

  const clientOfferAction = (
    id: number,
    action: OrderStatus.ORDER | OrderStatus.REJECTED
  ) => client.post(`/order/${id}/${action}`);

  return {
    getAll,
    getById,
    submit,
    getByStatus,
    updateStatus,
    adminGetByStatus,
    clientOfferAction,
    uploadFile
  };
};

export default useOrderApiService;
