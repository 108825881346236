import { t } from 'i18next';
import { Link } from 'react-router-dom';
import styles from './ResetPasswordForm.module.css'
import InitResetPasswordFormLogic from './ResetPasswordForm.logic';
import NotificationModalComponent from '../NotificationModal/NotificationModal.component';
import InputShared from '../../SharedComponents/InputShared/InputShared';

const ReesetPasswordFormComponent: React.FC = () => {
    const { data,
        errorMessage,
        isSubmitDisabled,
        presentNotificationModal,
        notificationModal,
        onSubmit,
        onChange,
        toggleShowPassword,
        showPassword } = InitResetPasswordFormLogic();

    return (
        <>
            <form onSubmit={onSubmit}>
                <h2 className={styles.title}>{t('Reset password')}</h2>
                <p className={styles.description}><span>*</span> {t('indicates a required field')}</p>

                <InputShared name="newPassword" labelText={t("New password")} required={true} onChange={onChange} type="password" placeholder='*******' value={data.newPassword}
                    mode="step" showHide={true} pass_strong={true} showPasswordGroup={showPassword} toggleGroupShow={toggleShowPassword} />

                <InputShared name="repeatPassword" labelText={t("Repeat new password")} required={true} onChange={onChange} type="password" placeholder='*******' value={data.repeatPassword}
                    mode="settings" showPasswordGroup={showPassword} toggleGroupShow={toggleShowPassword} />

                {errorMessage !== '' && <p className={styles.error__message}>{errorMessage}</p>}

                <button disabled={isSubmitDisabled} type='submit' className={styles.button}>{t("Save")}</button>
                <p className={styles.cta__paragraph}><Link to={'/'}>{t("Back to login")}</Link></p>
            </form>
            {presentNotificationModal && <NotificationModalComponent {...notificationModal}></NotificationModalComponent>}
        </>
    )
}

export default ReesetPasswordFormComponent;