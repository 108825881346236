import { t } from 'i18next';
import { Fragment } from "react";
import IPagination from "../../../interfaces/pagination";
import InitPaginationLogic from "./Pagination.logic";
import styles from './Table.module.css';

const Pagination: React.FunctionComponent<IPagination> = props => {
    const { activePage, totalPages, beginning, end, setActivePage } = InitPaginationLogic(props);
    
    return (
        <Fragment>
            {props.count > 0 && <>
                <div className={styles.pagination}>
                    <button className={`button-secondary`} disabled={activePage === 0} onClick={() => { props.request(0, props.rowsPerPage); setActivePage(0) }}>
                        &lt;&lt; {t('First')}
                    </button>
                    <button className={`button-secondary`} disabled={activePage === 0} onClick={() => { props.request(activePage - 1, props.rowsPerPage); setActivePage(activePage - 1) }}>
                        &lt; {t('Previous')}
                    </button>
                    <button className={`button-secondary`} disabled={activePage + 1 === totalPages} onClick={() => { props.request(activePage + 1, props.rowsPerPage); setActivePage(activePage + 1) }}>
                        {t('Next')} &gt;
                    </button>
                    <button className={`button-secondary`} disabled={activePage + 1 === totalPages} onClick={() => { props.request(totalPages - 1, props.rowsPerPage); setActivePage(totalPages - 1) }}>
                        {t('Last')} &gt;&gt;
                    </button>
                </div>
                <p>
                    {t('Page')} {activePage + 1} {t('of')} {totalPages}
                </p>
                <p>
                    {t('Showing')} {beginning === end ? `${end} - ${end}` : `${beginning} - ${end}`} {t('of')} {props.count} {t('items')}
                </p>
            </>}
        </Fragment>
    )
}

export default Pagination;