import IRoomFormData from "../../../../Interfaces/roomFormData";
import l100 from '../../../../../assets/images/wave3/visualization/s4-100kg-length.svg';
import w100 from '../../../../../assets/images/wave3/visualization/s4-100kg-width.svg';
import l200 from '../../../../../assets/images/wave3/visualization/s4-200kg-length.svg';
import w200 from '../../../../../assets/images/wave3/visualization/s4-200kg-width.svg';
import lNoWalk from '../../../../../assets/images/wave3/visualization/s4-nowalk-length.svg';
import wNoWalk from '../../../../../assets/images/wave3/visualization/s4-nowalk-width.svg';
import lOther from '../../../../../assets/images/wave3/visualization/s4-x-length.svg';
import wOther from '../../../../../assets/images/wave3/visualization/s4-x-width.svg';

import styles from './CRStep5Visualization.module.css'
import { SpanDirection } from "../../../../../enums/spanDirection";
import { Walkability } from "../../../../../enums/walkability";
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";

const CRStep5Visualization: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    return (
        <div className={styles.container}>
            {params.spanDirection === SpanDirection.ROOM_LENGTH &&
                <>
                    {params.walkability === Walkability.LESS_100 && <img className={styles.room__image} src={l100} alt='' />}
                    {params.walkability === Walkability.LESS_200 && <img className={styles.room__image} src={l200} alt='' />}
                    {params.walkability === Walkability.NOT_WALKABLE && <img className={styles.room__image} src={lNoWalk} alt='' />}
                    {params.walkability === Walkability.OTHER && <img className={styles.room__image} src={lOther} alt='' />}
                </>
            }

            {params.spanDirection === SpanDirection.ROOM_WIDTH &&
                <>
                    {params.walkability === Walkability.LESS_100 && <img className={styles.room__image} src={w100} alt='' />}
                    {params.walkability === Walkability.LESS_200 && <img className={styles.room__image} src={w200} alt='' />}
                    {params.walkability === Walkability.NOT_WALKABLE && <img className={styles.room__image} src={wNoWalk} alt='' />}
                    {params.walkability === Walkability.OTHER && <img className={styles.room__image} src={wOther} alt='' />}
                </>
            }


            {/* size  */}
            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>


            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>

            {/* center sistance limit */}
            <div className={`${styles.value__container} 
                ${params.spanDirection === SpanDirection.ROOM_LENGTH ? styles.center__distance__length : styles.center__distance__width}`}>
                <div className={styles.value}>{params.centerDistance} mm</div>
            </div>
        </div>
    )
}

export default CRStep5Visualization;