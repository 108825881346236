import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import IStepControls from "../../Interfaces/StepControls";
import IStepForm from "../../Interfaces/StepForm";

const InitStepFormLogic = (params: IStepForm) => {
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<number>(searchParams.get("step") !== null ? parseInt(searchParams.get("step")!) : 1);
  const navigate = useNavigate()
  const handlers = useSwipeable({
    onSwipedRight: () => {
      previous();
    },
    onSwipedLeft: () => {
      next();
    },
  });

  const stepBefore = () => {
    if (params.stepUrls) {
      let stepsBefore = params.stepUrls.filter((s) => s.before === true);
      try {
        return stepsBefore[stepsBefore.length - 1];
      } catch (er) {
        return undefined;
      }
    }
    return undefined;
  };

  const stepAfter = () => {
    if (params.stepUrls)
      for (let i = 0; i < params.stepUrls?.length; i++) {
        if (params.stepUrls[i].before === false) return params.stepUrls[i];
      }
    return undefined;
  };

  const next = () => {
    if (params.disableNext) {
      return;
    }

    let newStep = currentStep + 1;
    if (newStep > params.components.length) {
      params.submit();
      return;
    }

    setCurrentStep(newStep);
    window.scrollTo({ top: 0, left: 0 });
  };

  const previous = () => {
    let before = stepBefore();
    if (currentStep === 1 && before === undefined) return;

    if (before !== undefined && currentStep === 1) {
      before?.function();
      return;
    }

    setCurrentStep(currentStep - 1);
    window.scrollTo({ top: 0, left: 0 });
  };

  const controls: IStepControls = {
    disableNext: params.disableNext,
    disablePrevious: (currentStep === 1 && stepBefore() === undefined) || params.disablePrevious,
    hideNext: params.hideNext || (currentStep > params.components.length - 1 && stepAfter() === undefined),
    hidePrevious: params.hidePrevious || (currentStep === 1 && stepBefore() === undefined),
    next: next,
    previous: previous,
    submit: params.submit,
    hideSubmit: currentStep < params.components.length || stepAfter() !== undefined || params.components.length < 1 || params?.forceSubmit,
    currentStep: currentStep,
    components: params.components,
    backUrl: params.backUrl,
    backText: params.backText,
    hideBack: params.hideBack,
    forceSubmit: params.forceSubmit,
    externalSteps: params.stepUrls,
    submitText: params?.submitText,
    allowSkipTo: params.allowSkipTo,
    setCurrentStep: (step: number) => { setCurrentStep(step) },
    shouldSetBackButton: params.shouldSetBackButton
  };



  useEffect(() => {
    searchParams.set('step', currentStep.toString());
    navigate("?" + searchParams.toString(), { replace: true });
  }, [currentStep, searchParams, navigate])



  return { controls, handlers };
};

export default InitStepFormLogic;
