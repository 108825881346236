import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import IFileInfo from '../../../interfaces/fileInfo';
import IModal from '../../../Wave3/Interfaces/modal';
import IPage from '../../../interfaces/page';
import loginStyles from '../../Login/Login.module.css';
import styles from './Images.module.css';
import globalStyles from '../../../styles/Global.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import useFileApiService from '../../../Wave3/ApiService/fileApiService';
import useConnectionAngleApiService from '../../../Wave3/ApiService/connectionAngleApiService';
import useProfileApiService from '../../../Wave3/ApiService/profileApiService';
import useWallProfileApiService from '../../../Wave3/ApiService/wallProfileApiService';
import UtilsHelper from '../../../helpers/utils.helper';

const ImagesPage: React.FunctionComponent<IPage> = props => {
    let navigate = useNavigate();
    let { id, entity } = useParams();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [disableUpload, setDisableUpload] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [images, setImages] = useState<IFileInfo[]>([]);
    const [entityImages, setEntityImages] = useState<number[]>([]);
    const [selectedStates, setSelectedStates] = useState<boolean[]>([]);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [imageForDelete, setImageForDelete] = useState<IFileInfo>(null!);
    const [object, setObject] = useState<any>({});
    const { uploadImages, deleteFile, getImages } = useFileApiService()
    const { getConnectionAngleById, updateConnectionAngle } = useConnectionAngleApiService()
    const { getProfileById, updateProfile } = useProfileApiService()
    const { getWallProfileById, updateWallProfile } = useWallProfileApiService()

    const navigateUrl = () => {
        if (entity === "profile")
            return `/profiles/edit/${id}`;
        else if (entity === "connection-angle")
            return `/connection-angles/edit/${id}`;
        else if (entity === "wall-profile")
            return `/wall-profiles/edit/${id}`;
    }

    const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disableUpload) {
            return;
        }
        if (e === undefined) {
            return;
        }
        let selectedFiles = e.target.files;
        if (selectedFiles === null) {
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i])
        }
        setDisableUpload(true);
        uploadImages(formData).then(
            (response) => {
                request();
                setDisableUpload(false);
            },
            (error) => {
                setErrorMessage(t("Error, service unavailable. Please try again later."));
                setDisableUpload(false);
            }
        )
        e.target.files = null;
        e.target.value = null!;
    };

    const request = useCallback(() => {
        setLoading(true);
        getImages().then((response) => {
            if (!response.data.empty) {
                setImages(response.data);
            }
            setLoading(false);
            if (id && entity) {
                setLoading(true);
                let getEntities = getProfileById;
                if (entity === "connection-angle")
                    getEntities = getConnectionAngleById
                else if (entity === "wall-profile")
                    getEntities = getWallProfileById

                getEntities(Number(id))
                    .then(responseEntities => {
                        setObject(responseEntities.data);
                        if (responseEntities.data.fileInfos) {
                            setEntityImages(responseEntities.data.fileInfos.map((e: IFileInfo) => { return e.id }));
                            let states: boolean[] = [];
                            response.data.forEach((image: IFileInfo) => {
                                if (responseEntities.data.fileInfos.map((e: IFileInfo) => { return e.id }).includes(image.id)) {
                                    states.push(true);
                                }
                                else {
                                    states.push(false);
                                }
                            });
                            setSelectedStates(states);
                        }
                        else {
                            setSelectedStates(new Array(response.data?.length).fill(false));
                        }
                        setLoading(false);
                    })
            }
        });

        //eslint-disable-next-line
    }, [id, entity])

    useEffect(() => {
        request();
    }, [request])

    const select = (image: IFileInfo, index: number) => () => {
        if (!id) {
            return;
        }
        let newSelectedStates = [...selectedStates];
        newSelectedStates[index] = !newSelectedStates[index];
        setSelectedStates(newSelectedStates);

        if (entityImages.includes(image.id!)) {
            entityImages.splice(entityImages.indexOf(image.id!), 1);
        }
        else {
            entityImages.push(image.id!);
        }
        setEntityImages(entityImages);
    }

    const save = () => {
        setSubmitDisabled(true);
        object.fileInfos = [];
        for (let index = 0; index < selectedStates.length; index++) {
            if (selectedStates[index]) {
                object.fileInfos.push(images[index]);
            }
        }
        let updateEntity = updateProfile
        if (entity === "connection-angle")
            updateEntity = updateConnectionAngle
        else if (entity === "wall-profile")
            updateEntity = updateWallProfile

        updateEntity(object)
            .then(response => {
                navigate(navigateUrl()!);
            })
            .catch(error => {
                if (error.response?.status) {
                    if (error.response.status === 400) {
                        setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                    }
                    else {
                        setErrorMessage(t("Internal server error. Please try again later."));
                    }
                }
                else
                    setErrorMessage(t("Error, service unavailable. Please try again later."));

                setSubmitDisabled(false);
            });
    }

    const deleteImage = (image: IFileInfo) => {
        return new Promise((resolve: any, reject: any) => {
            deleteFile(image).then(
                (response) => {
                    setShowDeleteModal(false);
                    resolve();
                    request();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteImage(imageForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete image"),
        message: t("Are you sure bout this action?"),
        image: null!
    }

    const deleteFunction = (image: IFileInfo) => () => {
        setShowDeleteModal(true);
        setImageForDelete(image);
    }


    return (
        <>
            <NavigationComponent />
            <div className={globalStyles.layout}>
                <Fragment>
                    <div className={loginStyles.form__header}>
                        <h2 className={loginStyles.form__title}>{id ? t('Select images') : t('Images')}</h2>
                    </div>
                    {isLoading && <LoadingComponent></LoadingComponent>}

                    {!isLoading && images?.length < 1 &&
                        <div>
                            <div>
                                <p>{t('There is no images')}</p>
                            </div>
                        </div>
                    }


                    {!isLoading &&
                        <div className={globalStyles.layout}>
                            {id && <div className={styles.center}>
                                <Link to={navigateUrl()!}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                                {images?.length >= 1 && <button onClick={save} type="button" disabled={submitDisabled}>{t("Save")}</button>}
                                {images?.length < 1 && <Link to={`/images`}><button type="button">{t("Upload images")}</button></Link>}
                            </div>}
                            {errorMessage !== "" && <div className={styles.center}>
                                <p className={loginStyles.error__message}>{errorMessage}</p></div>
                            }

                            <div className={`${styles.images}`}>
                                {!id &&
                                    <div className={styles.dropdown__upload}>
                                        <input id="fileUploadInput" type="file" accept="image/*" name="file" multiple onChange={onFileChangeHandler} />
                                        <label htmlFor="fileUploadInput">{t("Drag and drop or click here")}</label>
                                    </div>
                                }

                                {images.map((image, index) =>
                                    <div className={styles.image__container} key={index} onClick={select(image, index)}>
                                        {!id && <button className={styles.delete__button} onClick={deleteFunction(image)} key={`${index}-x`}>
                                            <FontAwesomeIcon className={styles.dropdown__icon} icon={faTrash} />
                                        </button>}
                                        {id &&
                                            <div className={`${styles.select} ${selectedStates[index] ? styles.selected : ''}`}>
                                                <FontAwesomeIcon className={styles.dropdown__icon} icon={faCheck} />
                                            </div>
                                        }
                                        <img className={styles.image} src={UtilsHelper.createFileUrl(image.url)} alt=""></img>

                                        {image.name?.replace(image.name.split("_")[0] + "_", "")}
                                    </div>
                                )}
                            </div>

                        </div>
                    }
                    {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
                </Fragment>
            </div>
        </>
    )
}

export default ImagesPage;