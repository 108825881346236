import IRoute from "../interfaces/route";
import ErrorPage from "../pages/Error/ErrorPage";

import ProfilesPage from "../pages/Profile/Profiles/Profiles.page";
import EditProfilePage from "../pages/Profile/EditProfile/EditProfile.page";
import WallProfilesPage from "../pages/WallProfile/WallProfiles/WallProfiles.page";
import CreateEditWallProfilePage from "../pages/WallProfile/CreateEditWallProfile/CreateEditWallProfile.page";
import ConnectionAnglesPage from "../pages/ConnectionAngle/ConnectionAngles/ConnectionAngles.page";
import CreateEditConnectionAnglePage from "../pages/ConnectionAngle/CreateEditConnectionAngle/CreateEditConnectionAngle.page";
import FyksPage from "../pages/Fyk/Fyks/Fyks.page";
import CreateEditFykPage from "../pages/Fyk/CreateEditFyk/CreateEditFyk.page";
import ImagesPage from "../pages/Image/Images/Images.page";

// wave 3
import LoginPage from "../Wave3/pages/Login/Login.page";
import RegisterPage from "../Wave3/pages/Register/Register.page";
import ForgotPasswordPage from "../Wave3/pages/ForgotPassword/ForgotPassword.page";
import ResetPasswordPage from "../Wave3/pages/ResetPassword/ResetPassword.page";
import SelectProductPage from "../Wave3/pages/SelectProduct/SelectProduct.page";
import RoomOverviewPage from "../Wave3/pages/RoomOverview/RoomOverview.page";
import UserSettingsPage from "../Wave3/pages/UserSettings/UserSettings.page";
import UserUpdatePasswordPage from "../Wave3/pages/UserUpdatePassword/UserUpdatePassword.page";
import CreateEditSlidePage from "../pages/Slide/CreateEditSlide/CreateEditSlide.page";
import SlidesPage from "../pages/Slide/Slides/Slides.page";
import TermsPage from "../Wave3/pages/Terms/Terms.page";
import RoomFormPage from "../Wave3/pages/RoomForm/RoomFormPage";
import Configuration from "../pages/ConfigurationComponent/Configuration.component";
import CreateConfiguration from "../pages/ConfigurationComponent/CreateEditConfiguration/CreateConfiguration.component";
import DashboardPage from "../Wave3/pages/DashboardPage/Dashboard.page";
import ProjectsPage from "../Wave3/pages/Projects/Projects.page";
import CheckoutPage from "../Wave3/pages/Checkout/Checkout.page";
import OrdersPage from "../Wave3/pages/Orders/Orders.page";
import AdminOrdersPage from "../Wave3/pages/Admin/AdminOrders/AdminOrders.page";
import OrderPage from "../Wave3/pages/Order/Order.page";
import AdminFilesPage from "../Wave3/pages/Admin/AdminFiles/AdminFiles.page";

const routes: IRoute[] = [
    {
        path: "/",
        component: LoginPage,
    },
    {
        path: "/register",
        component: RegisterPage,
    },
    {
        path: "/terms",
        component: TermsPage,
    }, {
        path: "/checkout/:projectId",
        component: CheckoutPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/orders",
        component: OrdersPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/orders/:id",
        component: OrderPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/admin/orders",
        component: AdminOrdersPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/room",
        component: RoomFormPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/dashboard",
        component: DashboardPage,
        auth: ["ROLE_ADMIN", "ROLE_USER"]
    }, {
        path: "/projects",
        component: ProjectsPage,
        auth: ["ROLE_ADMIN", "ROLE_USER"]
    }, {
        path: "/user/settings",
        component: UserSettingsPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/user/security",
        component: UserUpdatePasswordPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/profiles",
        component: ProfilesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/profiles/create",
        component: EditProfilePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/profiles/edit/:id",
        component: EditProfilePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/reset/password",
        component: ForgotPasswordPage
    }, {
        path: "/reset/password/verify",
        component: ResetPasswordPage
    }, {
        path: "/wall-profiles",
        component: WallProfilesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/wall-profiles/create",
        component: CreateEditWallProfilePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/wall-profiles/edit/:id",
        component: CreateEditWallProfilePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/connection-angles",
        component: ConnectionAnglesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/connection-angles/create",
        component: CreateEditConnectionAnglePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/connection-angles/edit/:id",
        component: CreateEditConnectionAnglePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/fyks",
        component: FyksPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/fyks/create",
        component: CreateEditFykPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/fyks/edit/:id",
        component: CreateEditFykPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/slides",
        component: SlidesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/slides/create",
        component: CreateEditSlidePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/slides/edit/:id",
        component: CreateEditSlidePage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/images",
        component: ImagesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/files",
        component: AdminFilesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/images/:entity/:id",
        component: ImagesPage,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/room/:roomId/product",
        component: SelectProductPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/room/:roomId",
        component: RoomOverviewPage,
        auth: ["ROLE_USER", "ROLE_ADMIN"]
    }, {
        path: "/configuration",
        component: Configuration,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/configuration/create",
        component: CreateConfiguration,
        auth: ["ROLE_ADMIN"]
    }, {
        path: "/configuration/edit/:configurationKey",
        component: CreateConfiguration,
        auth: ["ROLE_ADMIN"]
    },
    {
        path: "/server-error",
        component: ErrorPage,
        props: { code: 500, error: "Server error" },
        allowLoggedUser: true
    },
    {
        path: "*",
        component: ErrorPage,
        props: { code: 404, error: "Page not found" },
        allowLoggedUser: true
    }
];

export default routes;