import { useEffect, useState } from "react";
import IRoomFormData from "../../../../Interfaces/roomFormData";

const InitCRStep6LogicVisualization = (params: IRoomFormData) => {
    const [sum, setSum] = useState<number>(0);

    useEffect(() => {
        let result: number = 0;
        if (params.constantLoads !== undefined) {
            params.constantLoads.forEach(e => { result += Number(e.value) });
        }
        setSum(result);
    }, [setSum, params.constantLoads]);


    return { sum }
}

export default InitCRStep6LogicVisualization;