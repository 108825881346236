import { DeflectionLimit } from "../../enums/deflectionLimit";
import { SpanDirection } from "../../enums/spanDirection";
import IRoom from "../../interfaces/room";
import IRoomFormData from "../Interfaces/roomFormData";

const getDeflectionLimiitString = (room: IRoomFormData | IRoom) => {
    if (room.deflectionLimit === -1) return DeflectionLimit.l500

    var l = room.length;
    if (room.spanDirection === SpanDirection.ROOM_WIDTH) {
        l = room.width;
    }

    if ((l / 500).toFixed(3) === room.deflectionLimit.toFixed(3)) {
        return DeflectionLimit.l500
    }
    if ((l / 300).toFixed(3) === room.deflectionLimit.toFixed(3)) {
        return DeflectionLimit.l300
    }
    return DeflectionLimit.OTHER
}

const roomHelper = {
    getDeflectionLimiitString,
}

export default roomHelper;