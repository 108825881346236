import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeflectionLimit } from "../../../enums/deflectionLimit";
import { SpanDirection } from "../../../enums/spanDirection";
import { TiltProtection } from "../../../enums/tiltProtection";
import { Walkability } from "../../../enums/walkability";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import IRoomFormData from "../../Interfaces/roomFormData";
import IStep from "../../Interfaces/Step";
import IStepForm from "../../Interfaces/StepForm";
import IStepIndicator from "../../Interfaces/StepIndicator";
import CRStep1Component from "./Step1/CRStep1.component";
import CRStep2Component from "./Step2/CRStep2.component";
import CRStep3Component from "./Step3/CRStep3.component";
import CRStep4Component from "./Step4/CRStep4.component";
import CRStep5Component from "./Step5/CRStep5.component";
import CRStep6Component from "./Step6/CRStep6.component";
import CRStep7Component from "./Step7/CRStep7.component";
import roomHelper from "../../helpers/roomHelper";
import useRoomApiService from "../../ApiService/roomApiService";

const InitRoomFormLogic = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IRoomFormData>({
        id: -1,
        name: '',
        centerDistance: 500,
        deflectionLimit: -1,
        traficLoads: 0,
        length: null!,
        width: null!,
        constantLoads: [],
        walkability: Walkability.LESS_100,
        spanDirection: SpanDirection.ROOM_WIDTH,
        partitionWallLoad: null!,
        tiltProtection: TiltProtection.ANTITILT_ANGLE,
        isExpertMode: false,
        deflectionLimitString: DeflectionLimit.l500,
        nextStep: 1,
        traficLoadsDescription: ""
    });
    const [shouldSetBackButton, setShouldSetBackButton] = useState<boolean>(false)
    const [skipTo, setSkipTo] = useState<number>(1);
    const [disablePrevious, setDisablePrevious] = useState(false);
    const [hideNext, setHideNext] = useState(false);
    const [hidePrevious, setHidePrevious] = useState(false);
    const [backUrlText, setBackUrlText] = useState("");
    const [disableNext, setDisableNextState] = useState<boolean>(false);
    const [hideBack, setHideBack] = useState<boolean>(false);

    const setDisableNext = (value: boolean, componentIndex: number) => {
        // this function is used to disable next step into step controls (dots) and next
        setDisableNextState(value);
        form.components[componentIndex]?.disableNext?.[1](value);
    }

    const { loadRoomOverview, submitRoom } = useRoomApiService()

    const stepUrls: IStepIndicator[] = [
        {
            before: false, function: () => {
                if (Number(searchParams.get("step")) === form.components.length && !disableNext) { submit(); return }
                if (data.nextStep > form.components.length) { navigate(`/room/${data.id}/product?step=1`) }
            }
        },
        { before: false, function: () => { if (data.nextStep > form.components.length) { navigate(`/room/${data.id}/product?step=2`) } } }
    ];

    const stepParams: IStep<IRoomFormData> = {
        data: data,
        setData: setData,
        setDisableNext: setDisableNext,
        setDisablePrevious: setDisablePrevious,
        setHideNext: setHideNext,
        setHidePrevious: setHidePrevious,
        options: {
            setBackText: (text: string) => {
                setBackUrlText(text)
            },
            setAllowSkipTo: (skipTo: number) => setSkipTo(skipTo),
            setHideBack: setHideBack
        },
        setShouldSetBackButton: setShouldSetBackButton
    }



    const submit = () => {
        let newData = { ...data };

        if (newData.deflectionLimitString === DeflectionLimit.l300) {
            newData.deflectionLimit = dataHandlerHelper.calculateDeflectionLimit(newData, 300)
        } if (newData.deflectionLimitString === DeflectionLimit.l500) {
            newData.deflectionLimit = dataHandlerHelper.calculateDeflectionLimit(newData, 500)
        }

        newData.constantLoads = newData.constantLoads.filter(x => x.value !== 0 && x.description !== '');
        newData.nextStep = 8;
        setData(newData)
        submitRoom(newData)
            .then(response => {
                navigate(`/room/${data.id}/product`);
            }).catch(error => {
                if (error.response?.status) {
                    if (error.response.status === 400) {
                        console.log(t(error.response?.data.translatableCode, { ns: "codes" }));
                    }
                    else {
                        console.log(t("Internal server error. Please try again later."));
                    }
                }
                else
                    console.log(t("Error, service unavailable. Please try again later."));
                setDisableNext(true, 0);
            });
    }

    const form: IStepForm = {
        components: [
            { component: CRStep1Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep2Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep3Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep4Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep5Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep6Component, params: stepParams, disableNext: useState(true) },
            { component: CRStep7Component, params: stepParams, disableNext: useState(true) }
        ],
        header: {
            title: null!,
            closeAction: null!,
            hideCloseAction: true
        },
        disableNext: disableNext,
        disablePrevious: disablePrevious,
        hideNext: hideNext,
        hidePrevious: hidePrevious,
        submit: () => { submit() },
        backText: backUrlText,
        backUrl: '/dashboard',
        hideBack: hideBack,
        forceSubmit: false,
        stepUrls: stepUrls,
        allowSkipTo: skipTo,
        shouldSetBackButton: shouldSetBackButton,
        setShouldSetBackButton: setShouldSetBackButton
    }

    useEffect(() => {
        if (searchParams.get("roomId") !== null) {
            loadRoomOverview(Number(searchParams.get("roomId")))
                .then(res => {
                    if (res.data.status === "ORDERED") {
                        navigate(`/room/${res.data.id}`, { replace: true })
                        return;
                    }
                    
                    var resData: IRoomFormData = res.data;
                    resData.deflectionLimitString = roomHelper.getDeflectionLimiitString(resData);
                    if (resData.length === 0) resData.length = null!
                    if (resData.width === 0) resData.width = null!
                    if (resData.constantLoads === undefined || resData.constantLoads === null) {
                        resData.constantLoads = []
                    }

                    if (searchParams.get("step") !== null) {
                        if (parseInt(searchParams.get("step")!) > resData.nextStep || parseInt(searchParams.get("step")!) > 7 || isNaN(parseInt(searchParams.get("step")!))) {
                            if (res.data.nextStep > 7) {
                                navigate(`/room?roomId=${resData.id}&step=7`, { replace: true })
                            }
                            else{
                                navigate(`/room?roomId=${resData.id}&step=${resData.nextStep}`, { replace: true })
                            }
                        }
                    }

                    setData(resData)
                    setSkipTo(res.data.nextStep)
                    setIsLoading(false)

                     if (res.data.nextStep < 8) navigate(`/room?roomId=${resData.id}&step=${resData.nextStep}`, { replace: true });


                }).catch(er => {
                    // TODO:
                    // handle errors
                    const jsonError = er.toJSON()
                    console.log(jsonError)
                    if (jsonError.status === 404) {
                        navigate('/not-found', { replace: true })
                    }else if(jsonError.status >= 500){
                        navigate("/server-error", {replace: true, state: {status: jsonError.status, message: jsonError.message}})
                    }

                    console.error(er)
                    // navigate(`/not-found`)
                })
        }
        else {
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, []);


    return { form, isLoading }
}

export default InitRoomFormLogic;
