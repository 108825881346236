import { useState } from "react";
import IAccordion from "../../Interfaces/accordion";

const InitAccordionLogic = (params: IAccordion) => {
    const [isOpen, setOpen] = useState(params.open ? params.open : false);

    const toggle = () => {
        setOpen(!isOpen)
    }

    return { isOpen, toggle }
}

export default InitAccordionLogic;