import { clientInstance } from "./clientInstance"


const useSlideApiService = () => {
    const client = clientInstance();

    const getSlidesByPage = (page: number, rowsPerPage: number) => 
        client.get<any>(`/slides/get?page=${page}&size=${rowsPerPage}&sort=orderNumber,asc`)

    const getSlideById = (id: number) => 
        client.get(`/slides/get/${id}`)

    const updateSlide = (formData: FormData) => 
        client.post(`/slides/update`, formData) 

    const createSlide = (formData: FormData) => 
        client.post(`/slides/create`, formData) 

    const deleteSlideRequest = (id: number) =>
        client.delete<any>(`/slides/delete/${id}`)

    const getSlideShow = () => 
        client.get<any>(`/slides/get`)


    return {getSlideById, updateSlide, createSlide, getSlidesByPage, deleteSlideRequest, getSlideShow}
}

export default useSlideApiService