import { t } from "i18next";
import IRoom from "../../../../interfaces/room";
import IAccordionContent from "../../../Interfaces/accordionContent";
import IRoomOverviewComponent from "../../../Interfaces/roomOverviewComponent";
import AccordionComponent from "../../Accordion/Accordion.component";
import PrintCalculationComponent from "../../PrintCalculation/PrintCalculation.component";
import styles from './CalcOverview.module.css'

const AccordionContent: React.FC<IAccordionContent<IRoomOverviewComponent>> = (params: IAccordionContent<IRoomOverviewComponent>) => {
    // const { calcResults } = InitCalcOverviewLogic(params.props!);
    return (<>
        <div className={styles.results}>
            <div className={styles.result__item}>
                <h4 className={styles.result__title}>{t("Support Reaction")}:</h4>
                <div className={styles.result__value}><p>A<sub>y,Ed</sub> = {params.props?.selectedProducts.profile?.calculationResult.ayed.toFixed(2)} kN</p></div>
            </div>

            <div className={styles.result__item}>
                <h4 className={styles.result__title}>{t("Existing Deflection")}:</h4>
                <div className={styles.result__value}><p>f <sub>vorh.</sub> = {params.props?.selectedProducts.profile.calculationResult.fvorh.toFixed(2)} mm</p> </div>
            </div>

            <div className={styles.result__item}>
                <h4 className={styles.result__title}>{t("Serviceability")}:</h4>
                <div className={styles.result__value}>
                    <div>
                        f<sub>vorh.</sub>
                        <div className={styles.devide}></div>
                        f<sub>zul.</sub>
                    </div>=
                    <div>
                        {params.props?.selectedProducts.profile.calculationResult.fvorh.toFixed(2)}
                        <div className={styles.devide}></div>
                        {params.props?.selectedProducts.profile.calculationResult.fzul.toFixed(2)}
                    </div> = {params.props?.selectedProducts.profile.calculationResult.serviceability.toFixed(2)} {'<'} 1
                </div>
            </div>

            <div className={styles.result__item}>
                <h4 className={styles.result__title}>{t("Bending Stress")}:</h4>
                <div className={styles.result__value}>
                    <div>
                        M<sub>y,Ed</sub>
                        <div className={styles.devide}></div>
                        M<sub>y,Rd</sub>
                    </div> =
                    <div>
                        {params.props?.selectedProducts.profile.calculationResult.myed.toFixed(2)}
                        <div className={styles.devide}></div>
                        {params.props?.selectedProducts.profile.calculationResult.myrd.toFixed(2)}
                    </div> = {params.props?.selectedProducts.profile.calculationResult.bendingStress.toFixed(2)} {'<'} 1
                </div>
            </div>
        </div>
        <div className={styles.result__item}>
            <PrintCalculationComponent {...params.props as unknown as IRoom} />
        </div>

    </>)
}



const CalcOverviewComponent: React.FC<IRoomOverviewComponent> = (params: IRoomOverviewComponent) => {
    return (<>
        <AccordionComponent title={t("Calculation overview")} open={params.openCalc} content={AccordionContent} props={params} />
    </>)
}


export default CalcOverviewComponent;