import styles from './Accordion.module.css'
import InitAccordionLogic from './Accordion.logic';
import arrowDown from '../../../assets/images/wave3/arrow-down.svg'
import arrowUp from '../../../assets/images/wave3/arrow-up.svg'
import IAccordion from '../../Interfaces/accordion';
import { t } from 'i18next';

const AccordionComponent: React.FC<IAccordion> = (params: IAccordion) => {
    const { isOpen, toggle } = InitAccordionLogic(params);

    return (
        <div className={styles.accordion}>
            <div className={styles.head} onClick={toggle}>
                <span>{t(params.title)}</span>
                {!isOpen && <img className={styles.icon}  src={arrowDown} alt="arrow down" />}
                {isOpen && <img className={styles.icon} src={arrowUp} alt="arrow down" />}
            </div>
            {isOpen && <div className={styles.content}><params.content props={{...params.props}}/></div>}
        </div>
    )
}

export default AccordionComponent;