import { useState } from 'react'
import { t } from 'i18next'
import useUserApiService from '../../ApiService/userApiService'

const InitEmailAlertLogic = () => {

    const [isLoading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>(t("Your email is not verified, check your email. If you can't see activation email please check spam."));
    const {reactivateVerificationCode} = useUserApiService()

    const resend = () => {
        setLoading(true);
        reactivateVerificationCode().then(
            (response) => {
                if (response.data != null) {
                    setMessage(t(response.data.translatableCode, {ns: "codes"}))
                }
                setLoading(false);
            });
    }

	return { message, isLoading, resend }
}

export default InitEmailAlertLogic;