import { t } from 'i18next';
import React from 'react'
import IRoomFormData from '../../../Interfaces/roomFormData';
import IStep from '../../../Interfaces/Step';
import InitCRStep1Logic from './CRStep1.logic';
import RFStyles from '../RoomForm.module.css'
import styles from './CRStep1.module.css'
import LoadingComponent from '../../Loading/Loading.component';

const CRStep1Component: React.FunctionComponent<IStep<IRoomFormData>> = (params: IStep<IRoomFormData>) => {
    const { onChange, isLoading } = InitCRStep1Logic(params);

    return (
        <div className={styles.container}>
            {isLoading && <LoadingComponent />}
            {!isLoading && <><h3 className={styles.title}>{t("Please enter the name of the project")}:</h3>
            <div className={styles.divider}></div>
            <input
                className={`${RFStyles.input} ${styles.input}`}
                type="text"
                placeholder={t("My new project...")}
                name='name'
                value={params.data.name}
                onChange={onChange}
            />
            </>
            }
        </div>
    )
}

export default CRStep1Component;