import NavigationComponent from '../../Wave3/Components/Navigation/Navigation.component';
import Table from '../../Wave3/Components/Table/Table.component';
import styles from "../../styles/Global.module.css"
import InitConfigurationLogic from './Configuration.logic';
import IPage from '../../interfaces/page';
import ModalComponent from '../../Wave3/Components/Modal/Modal.component';
import Pagination from '../../Wave3/Components/Table/Pagination.component';
import LoadingComponent from '../../Wave3/Components/Loading/Loading.component';

const ProductImages: React.FunctionComponent<IPage>  = () => {
    const {columns, headers, configurationItems, openDeleteModal, showDeleteModal, deleteModal, request, totalElements, isLoading} = InitConfigurationLogic()
   
    return (
        <>
        {isLoading && <LoadingComponent />}
           {!isLoading && <> 
                <NavigationComponent />
                    <div className={styles.layout}>
                            <div className={styles.layout}>
                                <Table columns={columns} headers={headers} rows={configurationItems} new="/configuration/create/" edit={`/configuration/edit/`} delete={openDeleteModal} editColumn={"configurationKey"} />
                                <Pagination count={totalElements} request={request} rowsPerPage={5}/>
                            </div>  
                    </div>
            </>}
            {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
        </>
    )
}

export default ProductImages