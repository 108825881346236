import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const Screws2OverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            <ProductOverviewComponent name="Montageset M8 8.8 (M8 x 30 SW 13)" calculationResult={undefined!} fileInfos={[{url: params.montageSetAssetPath ?? "" }]} id={undefined!}  />
        </>
    )
}

export default Screws2OverviewComponent;