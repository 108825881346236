import { create } from "zustand";
export enum CartNotificationType {
  ADDED = "added",
  REMOVED = "removed",
}

export const useCartStore = create<State & Action>((set) => ({
  projectsInCart: 0,
  shouldShowNotificationModal: false,
  notificationType: CartNotificationType.ADDED,
  projectsInTrash: 0,
  addToTrash: () =>
    set((state: State) => ({
      projectsInTrash: state.projectsInTrash + 1,
    })),
  removeFromTrash: () =>
    set((state: State) => ({
      projectsInTrash:
        state.projectsInTrash === 0 ? 0 : state.projectsInTrash - 1,
    })),
  moveFromTrashToCart: () =>
    set((state: State) => ({
      projectsInTrash:
        state.projectsInTrash === 0 ? 0 : state.projectsInTrash - 1,
      projectsInCart: state.projectsInCart + 1,
    })),
  removeProjectFromCartWithoutNotification: () =>
    set((state: State) => ({
      projectsInCart: state.projectsInCart === 0 ? 0 : state.projectsInCart - 1,
    })),
  addToCart: () =>
    set((state: State) => ({
      projectsInCart: state.projectsInCart + 1,
      notificationType: CartNotificationType.ADDED,
      shouldShowNotificationModal: true,
    })),
  removeFromCart: () =>
    set((state: State) => ({
      projectsInCart: state.projectsInCart === 0 ? 0 : state.projectsInCart - 1,
      projectsInTrash: state.projectsInTrash + 1,
      notificationType: CartNotificationType.REMOVED,
      shouldShowNotificationModal: true,
    })),
  setProjectsInTrash: (n: number) =>
    set((state: State) => ({ projectsInTrash: n })),
  setProjectsInCart: (n: number) =>
    set((state: State) => ({ projectsInCart: n })),
  showModal: () =>
    set((state: State) => ({ shouldShowNotificationModal: true })),
  hideModal: () =>
    set((state: State) => ({ shouldShowNotificationModal: false })),
}));

type State = {
  projectsInCart: number;
  shouldShowNotificationModal: boolean;
  notificationType: CartNotificationType;
  projectsInTrash: number;
};

type Action = {
  addToCart: () => void;
  removeProjectFromCartWithoutNotification: () => void;
  removeFromCart: () => void;
  moveFromTrashToCart: () => void;
  setProjectsInCart: (n: number) => void;
  showModal: () => void;
  hideModal: () => void;
  addToTrash: () => void;
  removeFromTrash: () => void;
  setProjectsInTrash: (n: number) => void;
};
