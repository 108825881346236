import { t } from "i18next";
import { Link } from "react-router-dom";
import UtilsHelper from "../../../helpers/utils.helper";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import LoadingComponent from "../Loading/Loading.component";
import PrintOrderComponent from "../PrintOrder/PrintOrder.component";
import SelectComponent from "../Select/Select.component";
import InitOrderLogic from "./Order.logic";
import styles from "./Order.module.css";
import arrowLeft from "../../../assets/images/wave3/arrow-left.svg";
import ModalComponent from "../Modal/Modal.component";
import { OrderStatus } from "../../../enums/orderStatus";
import { OrderCategory } from "../../../enums/orderCategory";
import PrintCalculationComponent from "../PrintCalculation/PrintCalculation.component";
import { faArrowUpRightFromSquare, faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomOverviewComponent from "../RoomOverview/RoomOverview.component";

const OrderComponent: React.FC = () => {
  const {
    order,
    isUserAdmin,
    statusChanged,
    statusMsg,
    selectOptions,
    selectedOption,
    showDeletePdfModal,
    deleteModal,
    fileUploadRef,
    getDefaultTextForSelectOption,
    handleStatusChange,
    submitNewStatus,
    uploadOrderPdf,
    openFileUploadWizard,
    deleteOfferFile,
    orderCategory,
    statusSelectionChanged,
    choiceActionModal,
    shouldShowChoiceModal,
    createActionConfirmModal,
  } = InitOrderLogic();

  return (
    <>
      {order === null && <LoadingComponent />}
      {order != null && (
        <>
          <span className={styles.created}>
            {UtilsHelper.formatDate(order.created)}
          </span>
          <div className={styles.header}>
            <h1 className={styles.title}>{order.name}</h1>
            {!isUserAdmin && (

              <div>
                {order.status === OrderStatus.PENDING && (
                  <span
                    className={`${styles.status} ${styles.status__draft}`}
                  >
                    {t("PENDING")}
                  </span>
                )}
                {order.status === OrderStatus.OFFER && (
                  <span
                    className={`${styles.status} ${styles.status__INPROGRESS}`}
                  >
                    {t(OrderStatus.OFFER)}
                  </span>
                )}
                {order.status === OrderStatus.ORDER && (
                  <span
                    className={`${styles.status} ${styles.status__ORDER}`}
                  >
                    {t(OrderStatus.ORDER)}
                  </span>
                )}
                {order.status === OrderStatus.EXECUTION && (
                  <span
                    className={`${styles.status} ${styles.status__EXECUTION}`}
                  >
                    {t(OrderStatus.EXECUTION)}
                  </span>
                )}
                {order.status === OrderStatus.DONE && (
                  <span className={`${styles.status} ${styles.status__DONE}`}>
                    {t("DONE")}
                  </span>
                )}
                {order.status === OrderStatus.REJECTED && (
                  <span
                    className={`${styles.status} ${styles.status__REJECTED}`}
                  >
                    {t("REJECTED")}
                  </span>
                )}
              </div>
            )}
          </div>



          {/* ADMIN SECTION  */}
          {isUserAdmin && (
            <div className={styles.admin__section}>
              <div className={styles.admin__buttons}>
                {order.materialDealer === '-' && <div>
                  <ButtonShared
                    type={"button"}
                    text={t("Upload PDF")}
                    onClick={openFileUploadWizard}
                  />

                  <input
                    type="file"
                    id="file"
                    ref={fileUploadRef}
                    style={{ display: "none" }}
                    onChange={uploadOrderPdf}
                  />
                </div>}
                <div className={styles.admin__status_selector__container}>
                  {selectOptions && <SelectComponent
                    defaultText={getDefaultTextForSelectOption()}
                    selected={selectedOption}
                    options={selectOptions}
                    onChange={handleStatusChange}
                  />}

                  {statusSelectionChanged && !statusChanged && (
                    <div onClick={submitNewStatus} className={styles.save__buton}>
                      {t("Save choice")}
                    </div>
                  )}

                  <br />
                  {statusChanged && (
                    <span className={styles.save_status_changed}>{statusMsg ?? t("Status changed")}</span>
                  )}
                </div>

              </div>

              <div className={styles.info__box__container}>
                <div className={styles.info__box}>
                  <div className={styles.info__box__title}>
                    {t("Customer info:")}
                  </div>
                  <div>
                    {t("First name")} : {order.user?.firstName} <br />
                    {t("Last name")} : {order.user?.lastName} <br />
                    {t("Email")} : {order.user?.username} <br />
                    {t("Phone")} : {order.user?.phone} <br />
                    {t("Address")} : {order.user?.address} <br />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* END OF ADMIN SECTION  */}

          <div className={styles.info__box__container}>
            <div className={styles.info__box}>
              <div className={styles.info__box__title}>
                {t("Material dealer:")}
              </div>
              {(order.materialDealer !== '-' || order.address !== '-') &&
                <div>
                  {t("Name")}: {order.materialDealer} <br />
                  {t("Address")}: {order.address} <br />
                  {t("House number")}: {order.houseNumber} <br />
                  {t("City")}: {order.city} <br />
                  {t("Post code")}: {order.postCode} <br />
                  {order?.contactPerson && <>{t("Contact person")}: {order.contactPerson}</>}
                </div>}
              {order.materialDealer === '-' && order.address === '-' && <div>{t("No data avilable")}</div>}

            </div>
          </div>

          <div className={styles.info__box__container}>
            <div className={styles.info__box}>
              <div className={styles.info__box__title}>
                {t("Offer")}:
              </div>
              <div>
                {order.fileInfos?.map((file, index) => {
                  return (
                    <div className={styles.file_download__button_container} key={index}>
                      <a className={styles.file_download__button} target="_blank" rel="noreferrer" href={`${UtilsHelper.createFileUrl(file.url)}`}>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: "6px" }} />
                        {file.name.replace(/^[^_]*_/, '')}
                      </a>
                      {isUserAdmin && (
                        <div className={styles.delete_file_button} onClick={() => deleteOfferFile(file)}>{t("Delete")}</div>
                      )}
                    </div>

                  )
                })}
                {!order.fileInfos && <span>{t("No data avilable")}</span>}

                {(!isUserAdmin && order.status === OrderStatus.OFFER) && <div className={styles.offer__response__buttons__container}>
                  <button
                    className={`${styles.offer__response__button} ${styles.offer__response__button__accept}`}
                    onClick={() => { createActionConfirmModal!(OrderStatus.ORDER) }}
                  >
                    {t("Accept")}
                    <FontAwesomeIcon
                      className={styles.customer__action__button__icon}
                      icon={faCheck}
                    />
                  </button>

                  <button className={`${styles.offer__response__button} ${styles.offer__response__button__reject}`}
                    onClick={() => { createActionConfirmModal!(OrderStatus.REJECTED) }}>
                    {t("Reject")}
                    <FontAwesomeIcon
                      className={styles.customer__action__button__icon}
                      icon={faX}
                    />
                  </button>
                </div>}

              </div>
            </div>
          </div>



          <div className={styles.info__box__container}>
            <div className={styles.info__box}>
              <div className={styles.info__box__title}>
                {t("Pre-dimensioning")}:
              </div>
              <div>
                {(isUserAdmin || (order.status === OrderStatus.DONE)) &&
                  <PrintCalculationComponent {...order?.rooms[0]} />
                }
                {(!isUserAdmin && (order.status !== OrderStatus.DONE)) &&
                  <span>{t("No data avilable")}</span>
                }
              </div>
            </div>
          </div>

          <div className={styles.info__box__container}>
            <div className={styles.info__box}>
              <div className={styles.info__box__title}>
                {t("Parts list")}:
              </div>
              <div>
                {(isUserAdmin || (order.status === OrderStatus.DONE)) &&
                  <PrintOrderComponent {...order} />
                }
                {(!isUserAdmin && (order.status !== OrderStatus.DONE)) &&
                  <span>{t("No data avilable")}</span>
                }
              </div>
            </div>
          </div>


          <div className={styles.info__box__container}>
            <div className={styles.info__box}>
              <div className={styles.info__box__title}>
                {t("Assembly instructions")}:
              </div>
              <div>
                {(order.instructions && (isUserAdmin || (order.status === OrderStatus.DONE))) &&
                  <div className={styles.file_download__button_container}>
                    <a className={styles.file_download__button} target="_blank" rel="noreferrer" href={`${order.instructions}`}>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: "6px" }} />
                      {t("Assembly instructions")}
                    </a>
                  </div>}
                {(!order.instructions || (!isUserAdmin && order.status !== OrderStatus.DONE)) &&
                  <span>{t("No data avilable")}</span>
                }
              </div>
            </div>
          </div>


          {/* 
          {order.files && (
            <div className={styles.info__box__container}>
              <div className={styles.info__box}>
                <div className={styles.info__box__title}>{t("Documents")}</div>
                <div>
                  {order.files?.map((file, index) => {
                    return (
                      <div className={styles.document__row} key={file.name}>
                        <a
                          className={styles.file__url}
                          target={"_blank"}
                          rel="noreferrer"
                          href={`${UtilsHelper.createFileUrl(file.url)}`}
                          key={index}
                        >
                          {file.name}
                        </a>
                        {isUserAdmin && (
                          <ButtonShared
                            onClick={() => deleteOfferFile(file)}
                            type="button"
                            text={t("Delete")}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )} */}

          <div className={styles.info__box__container}></div>

          {/* <div className={styles.projects}>
            <ProjectsListComponent
              isUserAdmin={isUserAdmin}
              room={order.rooms[0]}
              shouldShowChoiceButtons={
                [
                  OrderStatus.OFFER,
                  OrderStatus.ORDER,
                  OrderStatus.REJECTED,
                ].includes(order.status) &&
                projectHasOffer &&
                statusChanged === false &&
                isUserAdmin === false
              }
              choiceButtonAction={createActionConfirmModal}
              projects={order.rooms as unknown as IProjectItem[]}
              updateItem={() => { }}
            />
          </div> */}

          <RoomOverviewComponent {...{ ...order.rooms[0], hideControls: true, hideTitle: true, hideCalc: true, openCalc: false }} />

          <div className={styles.bottom__buttons}>
            <Link
              className={styles.checkout__link}
              to={
                orderCategory === OrderCategory.PROCESSING
                  ? "/orders?status=processing"
                  : "/orders?status=history"
              }
            >
              <ButtonShared
                type="button"
                img_src={arrowLeft}
                img_side={"left"}
                text={
                  orderCategory === OrderCategory.HISTORY
                    ? t("Back to order history")
                    : t("Back to projects in processing")
                }
              />
            </Link>
          </div>
        </>
      )}

      {showDeletePdfModal && <ModalComponent {...deleteModal} />}
      {shouldShowChoiceModal && <ModalComponent {...choiceActionModal} />}
    </>
  );
};

export default OrderComponent;
