import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { SpanDirection } from "../../../enums/spanDirection";
import IOrder from "../../Interfaces/order";
import IRoomOverview from "../../Interfaces/roomOverview";
import watermark from '../../../assets/images/watermark.png'
import imageHelper from "../../helpers/imageHelper";

const InitPrintOrderLogic = (order: IOrder) => {
    const [articles, setArticles] = useState<any[]>(null!);

    const [isLoading, setLoading] = useState<boolean>(true);

    const printRef = useRef<any>(null!);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => imageHelper.preloadImage(watermark),
        content: () => printRef.current,
        copyStyles: true,
    });

    useEffect(() => {

        document.title = `WSB - ${order.name}`;
        if (order?.articles !== undefined) {
            let firstPageArticles = order?.articles.slice(0, 17);
            let otherPagesArticles = order?.articles.slice(
                17,
                order?.articles.length + 1
            );
            let pieces = Math.ceil(otherPagesArticles.length / 27);
            let newArticles = [firstPageArticles];
            for (let index = 0; index < pieces; index++) {
                newArticles.push(otherPagesArticles.slice(index, index * 27 + 27));
            }
            setArticles(newArticles);
        }

        setLoading(false);

        return () => {
            document.title = "WSB";
        };
        // eslint-disable-next-line
    }, []);

    const calculateRoomLength = (room: IRoomOverview) => {
        var length = room.length;
        if (room.spanDirection === SpanDirection.ROOM_WIDTH) {
            length = room.width;
        }
        return length;
    };

    const currentDate = () => {
        let date = new Date();
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
    };

    return {
        printRef,
        handlePrint,
        isLoading,
        currentDate,
        articles,
        calculateRoomLength,
    };
};

export default InitPrintOrderLogic;
