import i18next, { t } from "i18next";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import mailIcon from '../../../assets/images/wave3/mail.svg';
import useUserApiService from "../../ApiService/userApiService";
import IFullUser from "../../../interfaces/fullUser";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import INotificationModal from "../../Interfaces/notificationModal";

const InitRegisterFormLogic = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [captcha, setCaptcha] = useState<any>("");
    const reCaptcha = useRef<any>(null);
    const [formData] = useState(new FormData());
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const { registerUser } = useUserApiService()
    const defaultData: IFullUser = {
        address: "",
        company: "",
        image: "",
        enabled: null!,
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        repeatPassword: "",
        username: "",
        genderTitle: "",
        language: i18next.language,
        acceptedGTPPD: false
    }
    const [data, setData] = useState<IFullUser>(defaultData);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const navigate = useNavigate();
    const [presentNotificationModal, setPresentNotificationModal] = useState<boolean>(false);
    const notificationModal: INotificationModal = {
        action: () => { setPresentNotificationModal(false); navigate('/') },
        close: () => { setPresentNotificationModal(false); setData(defaultData) },
        actionText: t('Back to login'),
        description: t("Registrtaion succesfull, please confirm your email via the link we sent you."),
        icon: mailIcon,
        title: t("Email has been sent")
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
    }

    const onPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
        setPassword(event.currentTarget.value)
    }

    const onReCaptchaChange = (value: any) => {
        setCaptcha(value);
    }


    const onSubmit = (event: React.SyntheticEvent) => {
        reCaptcha.current?.reset();
        event.preventDefault();
        if (isSubmitDisabled) {
            return;
        }
        if (captcha === "") {
            setErrorMessage("Please validate reCAPTCHA");
            return;
        }
        if (!data.acceptedGTPPD) {
            setErrorMessage("Please agree to the Terms and Conditions if you want to proceed");
            return;
        }
        setErrorMessage("");
        if (data.password !== data.repeatPassword) {
            setData({ ...data, password: "", repeatPassword: "" })
            return setErrorMessage("Passwords do not match, please enter matching passwords...");
        }
        setSubmitDisabled(true);
        formData.set('userString', JSON.stringify(data));
        registerUser(formData, captcha).then(response => {
            setPresentNotificationModal(true);
            setSubmitDisabled(false);
        })
            .catch(error => {
                if (error.response?.status) {
                    if (error.response.status === 400) {
                        setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                    }
                    else {
                        setErrorMessage(t('Internal server error. Please try again later.'));
                    }
                }
                else
                    setErrorMessage(t('Error, service unavailable. Please try again later.'));

                setSubmitDisabled(false);
            });
    }

    return { data, errorMessage, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange, onReCaptchaChange, reCaptcha, toggleShowPassword, showPassword, password, onPasswordChange }
}

export default InitRegisterFormLogic;