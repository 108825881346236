import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IConnectionAngle from '../../../interfaces/connectionAngle';
import IPage from '../../../interfaces/page';
import globalStyles from '../../../styles/Global.module.css';
import useConnectionAngleApiService from '../../../Wave3/ApiService/connectionAngleApiService';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import InputShared from '../../../Wave3/SharedComponents/InputShared/InputShared';
import loginStyles from '../../Login/Login.module.css';
import styles from '../../Login/Login.module.css';


const CreateEditConnectionAnglePage: React.FunctionComponent<IPage> = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [formValue, setformValue] = React.useState<IConnectionAngle>({
        id: null!,
        name: '',
        price: 0,
        fyrd: 0,
        selectedProducts: [],
        profiles: [],
        sku:""
    });

    const {getConnectionAngleById, updateConnectionAngle, createConnectionAngle} = useConnectionAngleApiService()

    useEffect(() => {
        if (id) {
            getConnectionAngleById(Number(id))
                .then(responseConnectionAngles => {
                    setformValue(responseConnectionAngles.data);

                }).catch(error => {
                    navigate(`/connection-angles`)
                })
        }
        //eslint-disable-next-line
    }, [id, navigate])

    const handleChange = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setformValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitDisabled(true);
        if (id) {
            updateConnectionAngle(formValue)
                .then(response => {
                    navigate(`/connection-angles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else if (error.response.status === 404) {
                            setErrorMessage(t("Connection angle not found"));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
        else {
            createConnectionAngle(formValue)
                .then(response => {
                    navigate(`/connection-angles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
    }

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <div className={styles.form__container}>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {errorMessage !== "" &&
                            <p className={loginStyles.error__message}>{errorMessage}</p>
                        }
                        <div className={loginStyles.form__header}>
                            <h2 className={loginStyles.form__title}>{id ? t("Edit connection angle") : t("Create connection angle")}</h2>
                            <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                        </div>

                        <InputShared name="name" labelText='Name' required={true} type="text" onChange={handleChange} value={formValue.name} />
                        <InputShared name="sku" labelText='SKU' required={true} type="text" onChange={handleChange} value={formValue.sku} />
                        <InputShared name="price" labelText="Price" type="number" step=".01" min="0" required={true} onChange={handleChange} value={formValue.price.toString()} />
                        <InputShared name="fyrd" labelText="fyrd" type="number" step=".01" min="0" required={true} onChange={handleChange} value={formValue.fyrd.toString()} />
            
                        {id && <Link to={`/images/connection-angle/${id}`}><button type="button" className="button-secondary">{t("Images")}</button></Link>}

                        <div className={loginStyles.form__bottom}>
                            <Link to={`/connection-angles`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                            <button disabled={submitDisabled} type="submit">{t("Save")}</button>
                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default CreateEditConnectionAnglePage;