import secondaryLogo from '../../../assets/images/edificio.jpg';
import logo from '../../../assets/images/logo.svg';
import FooterComponent from '../../Components/Footer/Footer.component';
import SwitchComponent from '../../Components/LanguageSwitch/LanguageSwitch.component';
import SliderComponent from "../../Components/Slider/Slider.component";
import ILayout from "../../Interfaces/layoutComponent";
import InitPublicLayoutLogic from "./Public.logic";
import styles from './Public.module.css';

const PublicLayout: React.FC<ILayout> = (props: ILayout) => {
    const { height, slides } = InitPublicLayoutLogic();

    return (
        <div className={styles.layout__container}>
            <div className={styles.layout} style={{ minHeight: height }}>
                <div className={styles.content__container}>
                    <div className={styles.content}>
                        <div className={styles.logo__container}>
                            <img src={logo} className={styles.main__logo} alt="Protektor logo" />
                            <img src={secondaryLogo} className={styles.secondary__logo} alt="Edificio logo" />
                        </div>
                        <div className={styles.component__container}>
                            <props.component />
                        </div>
                    </div>
                </div>
                <div className={styles.slider}>
                    <SliderComponent slides={slides}></SliderComponent>
                </div>
                <div className={styles.switch}>
                    <SwitchComponent />
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}


export default PublicLayout;
