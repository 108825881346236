import { t } from 'i18next';
import React from 'react'
import IStepHeader from '../../../Interfaces/StepHeader';
import styles from './StepHeader.module.css'

const StepHeaderComponent: React.FunctionComponent<IStepHeader> = (params: IStepHeader) => {

    return (
        <div>
            {params.title !== null && <h2 className={styles.title}>{params.title}</h2>}
            {!params.hideCloseAction && <button onClick={() => { params.closeAction() }}>{t("Close")}</button>}
        </div>
    )
}

export default StepHeaderComponent;