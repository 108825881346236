import IRoomFormData from "../../../Interfaces/roomFormData";
import IRoomOverview from "../../../Interfaces/roomOverview";
import CRStep2VisualizationComponent from "../../RoomForm/Step2/Visualization/CRStep2Visualization.component";
import styles from './GeometryOverview.module.css'

const GeometryOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <div>
            <div className={styles.overview__container}>
                <CRStep2VisualizationComponent  {...{ ...params as unknown as IRoomFormData }} />
            </div>
        </div>
    )
}

export default GeometryOverviewComponent;