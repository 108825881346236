import { useEffect, useRef, useState } from "react"
import ICookieModal from "../../Interfaces/cookieModal"
import { useMainLayoutStore } from "../../Stores/mainLayout.store"


const InitFooterLogic = () => {
    const { setFooterHeight } = useMainLayoutStore();
    const [showCookieModal, setShowCookieModal] = useState<boolean>(false)
    const footerRef = useRef<any>(null!);
    
    const cookieModal: ICookieModal = {
        close: () => { setShowCookieModal(false) }
    }

    useEffect(() => {
        const cookies = localStorage.getItem("cookies");
        if(cookies !== null){
              
        }
        else{
            setShowCookieModal(true)
        }
        onResize();
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])


    const onResize = () => {
        setFooterHeight(footerRef.current.offsetHeight)
    }


    return {showCookieModal, setShowCookieModal, cookieModal, footerRef}
}

export default InitFooterLogic