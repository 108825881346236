import { t } from "i18next";
import React from "react";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import InitCRStep6Logic from "./CRStep6.logic";
import styles from "../RoomForm.module.css";
import componentStyles from "./CRStep6.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CRStep6Visualization from "./Visualization/CRStep6Visualization.component";
import { PartitionWallLoad } from "../../../../enums/partitionWallLoad";
import InputShared from "../../../SharedComponents/InputShared/InputShared";
import LoadingComponent from "../../Loading/Loading.component";

const CRStep6Component: React.FunctionComponent<IStep<IRoomFormData>> = (
  params: IStep<IRoomFormData>
) => {
  const {
    onChange,
    onPartitionLoadChange,
    deleteItem,
    onPartitionWallChange,
    additionalLoad,
    cl,
    isLoading,
    invalidLoadIndexes,
  } = InitCRStep6Logic(params);
  return (
    <div className={styles.container}>
      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <>
          <div className={styles.data}>
            <h3 className={styles.title}>
              {t("Please enter the permanent loads")}
            </h3>
            <p className={styles.note}>
              <span>*</span> {t("Indicates a required field")}
            </p>
            <div
              className={`${componentStyles.row} ${componentStyles.labels__row}`}
            >
              <div>
                <label
                  className={`${styles.label} ${styles.ml10}`}
                  htmlFor="description"
                >
                  {t("Description")} <span aria-hidden="true">*</span>
                </label>
              </div>
              <div>
                <label
                  className={`${styles.label} ${styles.ml10}`}
                  htmlFor="value"
                >
                  {t("Load specification")} <span aria-hidden="true">*</span>
                </label>
              </div>
              <div></div>
            </div>
            {cl.map((object, index) => (
              <div key={index}>
                <div className={componentStyles.row}>
                  <InputShared
                    type="text"
                    name="description"
                    labelText=""
                    value={object.description}
                    placeholder={t("Plasterboard, Insulation, OSB...")}
                    onChange={onChange}
                    object={object}
                    shouldHaveErrorBorder={invalidLoadIndexes.includes(index)}
                  />

                  <InputShared
                    onChange={onChange}
                    name="value"
                    type="number"
                    value={object.value ?? ""}
                    min="0"
                    object={object}
                    labelText=""
                    units={
                      <>
                        {" "}
                        kg/m<sup>2</sup>{" "}
                      </>
                    }
                    required={false}
                    placeholder={t("Value")}
                  />

                  <div>
                    <FontAwesomeIcon
                      onClick={() => {
                        deleteItem(object);
                      }}
                      className={componentStyles.trash}
                      icon={faTrashAlt}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            ))}
            <br />
            <input
              disabled={0.01 * params.data.traficLoads >= 5}
              checked={additionalLoad && 0.01 * params.data.traficLoads < 5}
              onChange={onPartitionWallChange}
              id="additionalLoad"
              type="checkbox"
            />{" "}
            <label className={styles.label} htmlFor="additionalLoad">
              {t("Additional load of partition wall")}
            </label>
            {params.data.traficLoads >= 500 && (
              <p className={styles.description}>
                {t(
                  "To enable additional load option your traffic loads must be < 500kg/m"
                )}
              </p>
            )}
            {additionalLoad && 0.01 * params.data.traficLoads < 5 && (
              <div className="">
                <div>
                  <input
                    value={PartitionWallLoad.LESS_OR_EQUAL_3}
                    id={PartitionWallLoad.LESS_OR_EQUAL_3}
                    name="partitionWallLoad"
                    type="radio"
                    checked={
                      params.data.partitionWallLoad ===
                      PartitionWallLoad.LESS_OR_EQUAL_3
                    }
                    onChange={onPartitionLoadChange}
                  />
                  <label htmlFor={PartitionWallLoad.LESS_OR_EQUAL_3}>
                    {" "}
                    {t("Partition wall load incl. plaster ≤ 300 kg/m")}
                  </label>
                  <br />
                  <input
                    value={
                      PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5
                    }
                    id={
                      PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5
                    }
                    name="partitionWallLoad"
                    type="radio"
                    checked={
                      params.data.partitionWallLoad ===
                      PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5
                    }
                    onChange={onPartitionLoadChange}
                  />
                  <label
                    htmlFor={
                      PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5
                    }
                  >
                    {t("Partition wall load incl. plaster ≥ 300 kg/m and ≤ 500 kg/m")}
                  </label>
                </div>
              </div>
            )}
            <h5 className={styles.description__title}>{t("Whats that?")}</h5>
            <p className={styles.description}>
              {t(
                "The permanent loads to be carried by the wide span beams must be specified here in kg/m². For example, the suspended ceiling structure for an acoustic ceiling or exposed ceiling or the individual layers for a suspended ceiling that can be walked on."
              )}
            </p>
          </div>
          <div className={styles.visualization}>
            <CRStep6Visualization {...params.data} />
            <p>{`${params.data.name}`}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default CRStep6Component;
