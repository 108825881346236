import { t } from "i18next";
import React from "react";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import InitCRStep2Logic from "./CRStep2.logic";
import styles from "../RoomForm.module.css";
import InputShared from "../../../SharedComponents/InputShared/InputShared";
import SwitchButton from "../../../SharedComponents/SwitchButton/SwitchButton";
import { SpanDirection } from "../../../../enums/spanDirection";
import CRStep2VisualizationComponent from "./Visualization/CRStep2Visualization.component";
import LoadingComponent from "../../Loading/Loading.component";

const CRStep2Component: React.FunctionComponent<IStep<IRoomFormData>> = (
  params: IStep<IRoomFormData>
) => {
  const {
    onChange,
    setSpanDirection,
    getLengthErrors,
    getWidthErrors,
    isLoading,
  } = InitCRStep2Logic(params);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingComponent />}

      {!isLoading && (
        <>
          <div className={styles.data}>
            <h3 className={styles.title}>
              {t("Please determine the project geometry")}
            </h3>
            <p className={styles.note}>
              <span>*</span> {t("Indicates a required field")}
            </p>

            <SwitchButton
              title={t("Span direction")}
              required={true}
              default={
                params.data.spanDirection === SpanDirection.ROOM_WIDTH
                  ? "left"
                  : "right"
              }
              onClick={setSpanDirection}
              argumentLeft={SpanDirection.ROOM_WIDTH}
              argumentRight={SpanDirection.ROOM_LENGTH}
              leftButtonText={t("Room width")}
              rightButtonText={t("Room length")}
            />

            <hr className={styles.hr} />

            <InputShared
              type="number"
              name="length"
              labelText={t("Length of room")}
              placeholder={`${t("E.g.")} 4500`}
              value={
                params.data.length !== null
                  ? params.data.length?.toString()
                  : ""
              }
              onChange={onChange}
              min="500"
              units={<>mm</>}
              required={true}
              mode="step"
              errorMsg={getLengthErrors()}
              max={
                params.data.spanDirection === SpanDirection.ROOM_LENGTH
                  ? "10000"
                  : "10000000000000"
              }
            />

            <InputShared
              type="number"
              name="width"
              labelText={t("Width of room")}
              placeholder={`${t("E.g.")} 3000`}
              value={
                params.data.width !== null ? params.data.width?.toString() : ""
              }
              onChange={onChange}
              min="500"
              units={<>mm</>}
              required={true}
              mode="step"
              errorMsg={getWidthErrors()}
              max={
                params.data.spanDirection === SpanDirection.ROOM_WIDTH
                  ? "10000"
                  : "10000000000000"
              }
            />
            <h5 className={styles.description__title}>{t("Whats that?")}</h5>
            <p className={styles.description}>
              {t(
                "Here you can enter dimensions of your room (room length and width) and choose the direction in which you want your beams to span."
              )}
            </p>
          </div>
          <div className={styles.visualization}>
            <CRStep2VisualizationComponent {...params.data} />
            <p>{`${params.data.name}`}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default CRStep2Component;
