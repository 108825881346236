import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useRoomApiService from "../../ApiService/roomApiService";
import useBackButtonHelper from "../../helpers/backButtonHelper";
import IRoomOverviewComponent from "../../Interfaces/roomOverviewComponent";
import { useCartStore } from "../../Stores/cart.store";
import { RoomStatus } from "../../../enums/roomStatus";

const InitRoomOverviewLogic = (params: IRoomOverviewComponent) => {
  let { projectId, roomId } = useParams();
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { addRoomToCart, removeRoomFromCart } = useRoomApiService();
  const addProjectToCart = useCartStore((state) => state.addToCart);
  const removeProjectFromCart = useCartStore((state) => state.removeFromCart);
  const [projectStatus, setProjectStatus] = useState<String>(params.status);
  const [backButtonText, setBackButtonText] = useState<string>();
  const [backUrl, setBackUrl] = useState<String>();
  const [backTranslationKey, setBackTranslationKey] = useState<String>();
  const ordersRegex = new RegExp("orders");
  const {peekBackUrl, pushBackUrl, isOnTopOfStack, popBackUrl} = useBackButtonHelper()
  const backButtonOnClick = () => {
    // if(searchParams.get("mode") === "create") {popBackUrl(); pushBackUrl("/dashboard", "Back to dashboard")}
    navigate(String(backUrl ?? "/projects"), { replace: true });
  };

  const addToCart = () => {
    addRoomToCart(Number(params.id)).then((r) => {
      addProjectToCart();
      navigate("/projects?status=cart");
    });
  };

  const removeFromCart = () => {
    removeRoomFromCart(Number(params.id)).then((r) => {
      removeProjectFromCart();
      setProjectStatus("READY");
    });
  };

  useEffect(() => {
    // if(params.status === RoomStatus.DRAFT) navigate(`/room?roomId=${roomId}`)

    const {backUrl, backTranslationKey} = peekBackUrl()
    setBackUrl(backUrl)
    setBackTranslationKey(backTranslationKey)
    if(isOnTopOfStack("/room/")) popBackUrl()
    params.selectedProducts
      ? t("Go to ready projects")
      : t("Go to unfinished projects");
          
    return () => {
      pushBackUrl(`/room/${roomId}`, "Go to project overview")
      
    }
  }, []);

  return {
    projectId,
    roomId,
    searchParams,
    projectStatus,
    addToCart,
    removeFromCart,
    backButtonOnClick,
    backButtonText,
    backTranslationKey,
    backUrl,
    navigate
  };
};

export default InitRoomOverviewLogic;
