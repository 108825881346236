import FooterComponent from "../../Components/Footer/Footer.component";
import NavigationComponent from "../../Components/Navigation/Navigation.component";
import ILayout from "../../Interfaces/layoutComponent";
import styles from "./Main.module.css";
import InitMainLayoutLogic from "./MainLayout.logic";

const MainLayout: React.FC<ILayout> = (params: ILayout) => {
  const { contentHeight } = InitMainLayoutLogic();

  return (
    <>
      <NavigationComponent />
      <div className={styles.container} style={{ minHeight: `${contentHeight}px` }}>
        <div className={styles.content}>
          <params.component {...{ ...params.props }} />
        </div>
      </div>
      <FooterComponent></FooterComponent >
    </>
  );
};

export default MainLayout;
