import React from "react";
import InitDashboardLogic from "./Dashboard.logic";
import styles from "./Dashboard.module.css";
import newProjectIcon from "../../../assets/images/wave3/new-project.svg";
import readyProjectIcon from "../../../assets/images/wave3/ready-projects.svg";
import unfinishedProjects from "../../../assets/images/wave3/unfinished-projects.svg";
import ordersHistoryIcon from "../../../assets/images/wave3/orders.svg";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

const DashboardComponent: React.FunctionComponent = () => {
  const { statistics, handleChange, handleSearch } = InitDashboardLogic();
  return (
    <>
      <div className={styles.dashboard__overlay}></div>
      <h1 className={styles.title}>{t("Dashboard")}</h1>
      <div className={styles.dashboard}>
        <form className={styles.search} onSubmit={handleSearch}>
          <label htmlFor="search">
            <FontAwesomeIcon className={styles.search__icon} icon={faSearch} />
          </label>
          <input
            onChange={handleChange}
            id="search"
            placeholder={t("Search by project name or code...")}
            type="text"
          />
          <button type="submit">{t("Search...")}</button>
        </form>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <img
              className={styles.card__icon}
              src={newProjectIcon}
              alt="new project icon"
            />
            <span className={styles.card__title}>{t("New Projects")}</span>
            <span className={styles.card_item__number}></span>
          </div>
          <div
            className={`${styles.card__right} ${styles.call__to__action__button}`}
          >
            <Link to="/room">
              {" "}
              <button className={styles.card__button}>{t("Add")}</button>
            </Link>
            <span className={styles.tooltip}>
              {t("Here you can create a new project")}
            </span>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <img
              className={styles.card__icon}
              src={unfinishedProjects}
              alt="unfinished projects icon"
            />
            <span className={styles.card__title}>
              {t("Unfinished Projects")}
            </span>
            {statistics !== null && (
              <div
                className={`${styles.card_item__number} ${styles.card_item__number__yellow}`}
              >
                {statistics.unfinishedProjects}
              </div>
            )}
          </div>
          <div
            className={`${styles.card__right} ${styles.call__to__action__button}`}
          >
            <Link to="/projects?status=unfinished">
              <button className={styles.card__button}>{t("Show")}</button>
              <span className={styles.tooltip}>
                {t("Here you will find your incomplete projects")}
              </span>
            </Link>
          </div>
        </div>

        <div className={styles.wrapper}></div>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <img
              className={styles.card__icon}
              src={ordersHistoryIcon}
              alt="new project icon"
            />
            <span className={styles.card__title}>{t("Project History")}</span>
            {statistics !== null && (
              <div
                className={`${styles.card_item__number} ${styles.card_item__number__purple}`}
              >
                {statistics.orderHistory}
              </div>
            )}
          </div>
          <div
            className={`${styles.card__right} ${styles.call__to__action__button}`}
          >
            <Link to="/orders?status=history">
              <button className={styles.card__button}>{t("Show")}</button>
              <span className={styles.tooltip}>
                {t("Here you will find all done or rejected projects")}
              </span>
            </Link>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <img
              className={styles.card__icon}
              src={readyProjectIcon}
              alt="ready projects icon"
            />
            <span className={styles.card__title}>{t("Ready Projects")}</span>
            {statistics !== null && (
              <div
                className={`${styles.card_item__number} ${styles.card_item__number__green}`}
              >
                {statistics.processingProjects}
              </div>
            )}
          </div>
          <div
            className={`${styles.card__right} ${styles.call__to__action__button}`}
          >
            <Link to="/orders?status=processing">
              <button className={styles.card__button}>{t("Show")}</button>
              <span className={styles.tooltip}>
                {t(
                  "Here you will find your projects in progress with their respective current status"
                )}
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card__left}>
            <div className={`${styles.card__icon}`}>
              <FontAwesomeIcon
                icon={faTrash}
                style={{ color: "#9D9D9D" }}
              ></FontAwesomeIcon>
            </div>
            <span className={styles.card__title}>{t("Trash")}</span>
            {statistics !== null && (
              <div
                className={`${styles.card_item__number} ${styles.card_item__number__grey}`}
              >
                {statistics.projectsInTrash}
              </div>
            )}
          </div>
          <div
            className={`${styles.card__right} ${styles.call__to__action__button}`}
          >
            <Link to="/projects?status=trash">
              <button className={styles.card__button}>{t("Show")}</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
