import IRoomFormData from "../../../../Interfaces/roomFormData";
import lAntitilt from '../../../../../assets/images/wave3/visualization/s6-antitilt-length.svg';
import wAntitilt from '../../../../../assets/images/wave3/visualization/s6-antitilt-width.svg';
import lPlank from '../../../../../assets/images/wave3/visualization/s6-plank-length.svg';
import wPlank from '../../../../../assets/images/wave3/visualization/s6-plank-width.svg';

import styles from './CRStep7Visualization.module.css'
import { SpanDirection } from "../../../../../enums/spanDirection";
import { TiltProtection } from "../../../../../enums/tiltProtection";
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";

const CRStep7Visualization: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    return (
        <div className={styles.container}>
            {params.spanDirection === SpanDirection.ROOM_LENGTH &&
                <>
                    {params.tiltProtection === TiltProtection.ANTITILT_ANGLE && <img className={styles.room__image} src={lAntitilt} alt='' />}
                    {params.tiltProtection === TiltProtection.STIFFENING_PLANKING && <img className={styles.room__image} src={lPlank} alt='' />}
                </>
            }

            {params.spanDirection === SpanDirection.ROOM_WIDTH &&
                <>
                    {params.tiltProtection === TiltProtection.ANTITILT_ANGLE && <img className={styles.room__image} src={wAntitilt} alt='' />}
                    {params.tiltProtection === TiltProtection.STIFFENING_PLANKING && <img className={styles.room__image} src={wPlank} alt='' />}

                </>
            }


            {/* size  */}
            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>


            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>

            {/* center sistance limit */}
            <div className={`${styles.value__container} 
                ${params.spanDirection === SpanDirection.ROOM_LENGTH ? styles.center__distance__length : styles.center__distance__width}`}>
                <div className={styles.value}>{params.centerDistance} mm</div>
            </div>
        </div>
    )
}

export default CRStep7Visualization;