import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Link } from "react-router-dom";
import secondaryLogo from "../../../assets/images/edificio.jpg";
import mainLogo from "../../../assets/images/logo.svg";
import closeMenuIcon from "../../../assets/images/wave3/arrow-right-black.svg";
import menuIcon from "../../../assets/images/wave3/menu.svg";
import userIcon from "../../../assets/images/wave3/user.svg";
import SwitchComponent from "../LanguageSwitch/LanguageSwitch.component";
import EmailAlertComponent from "../EmailAlert/EmailAlert.component";
import InitNavigationLogic from "./Navigation.logic";
import styles from "./Navigation.module.css";
import UtilsHelper from "../../../helpers/utils.helper";

const NavigationComponent: React.FC = () => {
  const {
    navigationRef,
    show,
    toggleMenu,
    user,
    open,
    logout,
    isAdmin,
    openSwipeNavhandler,
    closeSwipeNavhandler,
    projectsInCart,
  } = InitNavigationLogic();
  return (
    <div id="navigation">
      {show && (
        <>
          <div ref={navigationRef}>
            {!user.enabled && <EmailAlertComponent />}
            <div {...openSwipeNavhandler} className={styles.container}>
              <div className={styles.navigation}>
                <Link to="/dashboard">
                  <div className={styles.navigation__item}>
                    <img
                      className={styles.main__logo}
                      src={mainLogo}
                      alt="protektor logo"
                    />
                    <img
                      className={styles.secondary__logo}
                      src={secondaryLogo}
                      alt="edeficio logo"
                    />
                  </div>
                </Link>

                <div className={styles.navigation__item}>
                  {user !== null! && (
                    <>
                      <div
                        id="userMenu"
                        tabIndex={1}
                        className={`${styles.dropdown} ${styles.hide__mobile}`}
                      >
                        <div className={styles.logged__user}>
                          <span className={styles.user__name}>{`${
                            user.firstName
                          } ${user?.lastName ?? ""}`}</span>
                          <img
                            className={styles.user__image}
                            src={
                              user.image
                                ? `${UtilsHelper.createFileUrl(user.image)}`
                                : userIcon
                            }
                            alt="user"
                          />
                        </div>
                        <div className={styles.dropdown__content}>
                          <div className={styles.dropdown__section__title}>
                            {t("Settings")}
                          </div>
                          <Link to={"/user/settings"}>
                            <button className={styles.dropdown__button}>
                              {t("Edit profile")}
                            </button>
                          </Link>

                          <div className={styles.dropdown__section__title}>
                            {t("Security")}
                          </div>
                          <Link to={"/user/security"}>
                            <button className={styles.dropdown__button}>
                              {t("Change password")}
                            </button>
                          </Link>
                          <button
                            onClick={logout}
                            className={styles.dropdown__button}
                          >
                            {t("Logout")}
                          </button>
                        </div>
                      </div>

                      <div className={styles.menu__icon__container}>
                        <img
                          onClick={toggleMenu}
                          className={styles.user__image}
                          src={
                            user.image
                              ? UtilsHelper.createFileUrl(user.image)
                              : userIcon
                          }
                          alt="user"
                        />
                        <button onClick={toggleMenu} className={styles.button}>
                          <img
                            id="navigationMenu"
                            className={styles.menu__icon}
                            src={menuIcon}
                            alt="menu"
                          />
                        </button>
                      </div>
                    </>
                  )}

                  <Link
                    className={styles.cart}
                    to={"/projects?status=cart"}
                    style={{ marginRight: "16px" }}
                  >
                    <span className={styles.cart__badge}>
                      {projectsInCart < 100 ? projectsInCart : "99+"}
                    </span>
                    <FontAwesomeIcon
                      className={styles.cart__icon}
                      icon={faShoppingCart}
                    ></FontAwesomeIcon>
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.overlay}></div>
          </div>

          {open && (
            <div onClick={toggleMenu} className={styles.overlay__menu}></div>
          )}

          <div
            tabIndex={open ? 0 : -1}
            {...closeSwipeNavhandler}
            className={`${styles.menu} ${open ? styles.menu__show : ""}`}
          >
            <button className={styles.close_menu_button} onClick={toggleMenu}>
              <img src={closeMenuIcon} alt="close menu" />
            </button>

            <div className={styles.menu__user}>
              <img
                className={styles.menu__user__image}
                src={
                  user.image ? UtilsHelper.createFileUrl(user.image) : userIcon
                }
                alt="user"
              />
              <br />
              <span className={styles.c}>{`${user.firstName} ${
                user?.lastName ?? ""
              }`}</span>
            </div>
            <div className={styles.menu__group__title}>{t("Menu")}</div>
            <Link to="/dashboard">
              <button className={styles.menu__button}>
                {t("My dashboard")}
              </button>
            </Link>
            <Link to="/room">
              <button className={styles.menu__button}>
                {t("Start new project")}
              </button>
            </Link>

            {isAdmin && (
              <>
                <div className={styles.menu__group__title}>{t("Orders")}</div>
                <Link to="/admin/orders">
                  <button className={styles.menu__button}>{t("Orders")}</button>
                </Link>
                <div className={styles.menu__group__title}>{t("Products")}</div>
                <Link to="/profiles">
                  <button className={styles.menu__button}>
                    {t("Profiles")}
                  </button>
                </Link>
                <Link to="/wall-profiles">
                  <button className={styles.menu__button}>
                    {t("Wall connection profile")}
                  </button>
                </Link>
                <Link to="/connection-angles">
                  <button className={styles.menu__button}>
                    {t("Connection angles")}
                  </button>
                </Link>
                <div className={styles.menu__group__title}>{t("Media")}</div>
                <Link to="/images">
                  <button className={styles.menu__button}>{t("Images")}</button>
                </Link>
                <Link to="/files">
                  <button className={styles.menu__button}>{t("Files")}</button>
                </Link>
                <div className={styles.menu__group__title}>
                  {t("Configurations")}
                </div>
                <Link to="/fyks">
                  <button className={styles.menu__button}>{t("Fyk")}</button>
                </Link>
                <Link to="/slides">
                  <button className={styles.menu__button}>{t("Slider")}</button>
                </Link>
                <Link to="/configuration">
                  <button className={styles.menu__button}>
                    {t("Configuration")}
                  </button>
                </Link>
              </>
            )}
            {/* <div className={styles.menu__group__title}>{t("Support")}</div> */}
            {/* <Link  to='/'><button className={styles.menu__button}>{t("Help")}</button></Link> */}
            <div className={styles.menu__group__title}>{t("Language")}</div>
            <SwitchComponent />
            <div className={styles.menu__user__item}>
              <div className={styles.menu__group__title}>{t("User")}</div>
              <Link to="/user/settings">
                <button className={styles.menu__button}>
                  {t("Edit profile")}
                </button>
              </Link>
              <Link to="/user/security">
                <button className={styles.menu__button}>
                  {t("Change password")}
                </button>
              </Link>
              <button onClick={logout} className={`${styles.menu__button}`}>
                {t("Logout")}
              </button>
            </div>
          </div>
        </>
      )}{" "}
    </div>
  );
};

export default NavigationComponent;
