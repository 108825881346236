import { useEffect, useState } from 'react';
import IModal from '../../Interfaces/modal';

const InitModalLogic = (modal: IModal) => {

    const [isLoading, setLoading] = useState<boolean>(false);

    const confirm = () => {
        setLoading(true);
        modal.confirmAction().catch((err: any) => { setLoading(false); alert(err); });
    }

    useEffect(()=>{
        document.body.style.overflowY = "hidden";
        return ()=>{
            document.body.style.overflowY = "";
        }
    })
	return { isLoading, confirm }
}

export default InitModalLogic;