import IRoomFormData from "../../../../Interfaces/roomFormData";
import lLoad from '../../../../../assets/images/wave3/visualization/s5-load-length.svg';
import wLoad from '../../../../../assets/images/wave3/visualization/s5-load-width.svg';
import lMoreLoad from '../../../../../assets/images/wave3/visualization/s5-moreload-length.svg';
import wMoreLoad from '../../../../../assets/images/wave3/visualization/s5-moreload-width.svg';

import styles from './CRStep6Visualization.module.css'
import { SpanDirection } from "../../../../../enums/spanDirection";
import InitCRStep6LogicVisualization from "./CRStep6Visualization.logic";
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";

const CRStep6Visualization: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    const { sum } = InitCRStep6LogicVisualization(params);

    return (
        <div className={styles.container}>
            {params.spanDirection === SpanDirection.ROOM_LENGTH &&
                <>
                    {params.partitionWallLoad === null && <img className={styles.room__image} src={lLoad} alt='' />}
                    {params.partitionWallLoad !== null && <img className={styles.room__image} src={lMoreLoad} alt='' />}
                </>
            }

            {params.spanDirection === SpanDirection.ROOM_WIDTH &&
                <>
                    {params.partitionWallLoad === null && <img className={styles.room__image} src={wLoad} alt='' />}
                    {params.partitionWallLoad !== null && <img className={styles.room__image} src={wMoreLoad} alt='' />}

                </>
            }

            <div className={`${styles.value__container} ${styles.main__value}`}>
                <div className={styles.value}>
                    {sum.toFixed(2)} kg/m<sup>2</sup>
                </div>
            </div>


            {/* size  */}
            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>

            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>

            {/* center sistance limit */}
            <div className={`${styles.value__container} 
                ${params.spanDirection === SpanDirection.ROOM_LENGTH ? styles.center__distance__length : styles.center__distance__width}`}>
                <div className={styles.value}>{params.centerDistance} mm</div>
            </div>
        </div>
    )
}

export default CRStep6Visualization;