import i18next, { t } from "i18next";
import mainLogo from "../../../assets/images/logo.svg";
import secondaryLogo from "../../../assets/images/edificio.jpg";
import watermark from "../../../assets/images/watermark.png";
import noImage from "../../../assets/images/wave3/noimage.svg";

import IOrder from "../../Interfaces/order";
import RoomOverviewItemComponent from "../RoomOverview/RoomOverviewItem/RoomOverviewItem.component";
import InitPrintOrderLogic from "./PrintOrder.logic";
import styles from "./PrintOrder.module.css";
import { SpanDirection } from "../../../enums/spanDirection";
import roomHelper from "../../helpers/roomHelper";
import { DeflectionLimit } from "../../../enums/deflectionLimit";
import IRoomFormData from "../../Interfaces/roomFormData";
import { Walkability } from "../../../enums/walkability";
import { TiltProtection } from "../../../enums/tiltProtection";
import AntiTiltAngleOverviewComponent from "../RoomOverview/ProductOverview/AntiTiltAngle.component";
import Screws2OverviewComponent from "../RoomOverview/ProductOverview/Screws2Overview.component";
import Screws1OverviewComponent from "../RoomOverview/ProductOverview/Screws1Overview.component";
import UtilsHelper from "../../../helpers/utils.helper";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const PrintOrderComponent: React.FunctionComponent<IOrder> = (
  params: IOrder
) => {
  const { printRef, handlePrint, currentDate, articles, calculateRoomLength } =
    InitPrintOrderLogic(params);

  return (
    <>
      <button className={styles.print__button} onClick={handlePrint}>
        <FontAwesomeIcon icon={faPrint} style={{ marginRight: "6px" }} />
        {t("Parts list")}
      </button>

      <div className={styles.print__content} ref={printRef}>
        <div
          className={styles.page}
          style={{ background: `url('${watermark}')` }}
        >
          <div className={styles.page__header}>
            <img src={mainLogo} alt="protektor logo" />
            <img src={secondaryLogo} alt="edeficio logo" />
          </div>
          <div className={styles.project__info}>
            <div>
              <b>{t("Customer info")}</b>
              <br />
              {params.user?.firstName} {params.user?.lastName}
              <br />
              {params.user?.address}
              <br />
              {params.user?.phone}
              <br />
              {params.user?.username}
              <br />
              <br />
              <b>{t("Project name")}</b>
              <br />
              {params.name}
              <br />
            </div>
            <div>
              {currentDate()}
              <b>{t("Contact:")}</b> <br />
              {t("protektor mail")} <br />
              <br />
              <b>{t("Material dealer:")}</b>
              <br />
              {params.materialDealer}
            </div>
          </div>

          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("Pos.")}</th>
                <th>{t("Art. Nr.")}</th>
                <th>{t("Article")}</th>
                <th>{t("Quantity")}</th>
                <th>{t("Single length")}</th>
                <th>{t("Total length")}</th>
                <th>{t("Unit")}</th>
              </tr>
            </thead>
            <tbody>
              {articles !== null &&
                articles[0].map((article: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{article.sku}</td>
                    <td>{article.name}</td>
                    <td>{article.quantity}</td>
                    {article.singleLength !== 0 &&
                      article.singleLength !== null && (
                        <td>
                          {(article.singleLength / 1000).toLocaleString(
                            i18next.language
                          )}
                          m
                        </td>
                      )}
                    {(article.singleLength === 0 ||
                      article.singleLength === null) && (
                        <td style={{ background: "#ddd" }}></td>
                      )}

                    {article.totalLength !== 0 &&
                      article.totalLength !== null && (
                        <td>
                          {(article.totalLength / 1000).toLocaleString(
                            i18next.language
                          )}
                          m
                        </td>
                      )}

                    {(article.totalLength === 0 ||
                      article.totalLength === null) && (
                        <td style={{ background: "#ddd" }}></td>
                      )}
                    <td>{t(article.unit)}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className={styles.page__footer}>
            <a href="https://www.protektor.de/de/">{t("www.protektor.de")}</a>
            <span>
              {t("Date:")} {currentDate()}
            </span>
          </div>
        </div>

        {articles?.map(
          (ariclesItem, index) =>
            index > 0 && (
              <div
                key={index}
                className={styles.page}
                style={{ background: `url('${watermark}')` }}
              >
                <div className={styles.page__header__secondary}>
                  <span>
                    <b>{t("Order:")} </b>
                    {params.id}
                  </span>
                  <br />
                  <div className={styles.page__header__pagination}>
                    <b>
                      {t("Page")} {index + 1}
                    </b>
                  </div>
                </div>

                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t("ID")}</th>
                      <th>{t("SKU")}</th>
                      <th>{t("Article")}</th>
                      <th>{t("Quantity")}</th>
                      <th>{t("Unit")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ariclesItem.map((article: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{article.sku}</td>
                        <td>{article.name}</td>
                        <td>{article.quantity}</td>
                        <td>{article.unit}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className={styles.page__footer}>
                  <a
                    href="https://www.protektor.de/de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("www.protektor.de")}
                  </a>
                  <span>
                    {t("Date:")} {currentDate()}
                  </span>
                </div>
              </div>
            )
        )}

        {params?.rooms.map((room, index) => (
          <div
            key={index}
            className={styles.page}
            style={{ background: `url('${watermark}')` }}
          >
            {/* header  */}
            <div className={styles.page__header__secondary}>
              <div className={styles.page__header__pagination}>
                <span>
                  <b>{t("Project number:")} </b> {room.code}
                </span>
                <b>
                  {t("Page")} {index + 1 + articles?.length}
                </b>
              </div>
            </div>
            {/* end of header */}

            {/* room */}
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Room")}
                  </td>
                  <td>{room.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Room length")}
                  </td>
                  <td>
                    {room.length}mm ({room.length / 1000}m)
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Room width")}
                  </td>
                  <td>
                    {room.width}mm ({room.width / 1000}m)
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Span direction")}
                  </td>
                  <td>
                    {room.spanDirection === SpanDirection.ROOM_LENGTH && (
                      <>{t("Room length")}</>
                    )}
                    {room.spanDirection === SpanDirection.ROOM_WIDTH && (
                      <>{t("Room width")}</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Center distance")}
                  </td>
                  <td>{room.centerDistance}mm</td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Deflection limit")}
                  </td>
                  <td>
                    {roomHelper.getDeflectionLimiitString(
                      room as unknown as IRoomFormData
                    ) === DeflectionLimit.l300 && <span>{t("l/300")}</span>}
                    {roomHelper.getDeflectionLimiitString(
                      room as unknown as IRoomFormData
                    ) === DeflectionLimit.l500 && <span>{t("l/500")}</span>}
                    {roomHelper.getDeflectionLimiitString(
                      room as unknown as IRoomFormData
                    ) === DeflectionLimit.OTHER && <span>{t("Other")}</span>}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Walkability")}
                  </td>
                  <td>
                    {room.walkability === Walkability.OTHER && (
                      <>{t("Other traffic loads")}</>
                    )}
                    {room.walkability === Walkability.LESS_100 && (
                      <>{t("Walkable (< 100 kg/m2)")}</>
                    )}
                    {room.walkability === Walkability.LESS_200 && (
                      <>{t("Walkable (< 200 kg/m2)")}</>
                    )}
                    {room.walkability === Walkability.NOT_WALKABLE && (
                      <>{t("Not walkable")}</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("Permanent loads")}
                  </td>
                  <td>{room.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "40%", fontWeight: "bold" }}>
                    {t("The desired top-side anti-tilt variant")}
                  </td>
                  <td>
                    {room.tiltProtection === TiltProtection.ANTITILT_ANGLE && (
                      <>{t("Anti-tilt angle on top side")}</>
                    )}
                    {room.tiltProtection ===
                      TiltProtection.STIFFENING_PLANKING && (
                        <>{t("Stiffening planking on the top side")}</>
                      )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={styles.page__beams}>
              <div>
                <div className={styles.page__beams__image}>
                  {room.selectedProducts.profile.fileInfos === undefined && (
                    <img alt="" className={styles.image} src={noImage} />
                  )}
                  {room.selectedProducts.profile.fileInfos !== undefined && (
                    <>
                      {room.selectedProducts.profile.fileInfos.length > 0 && (
                        <img
                          alt=""
                          className={styles.image}
                          src={UtilsHelper.createFileUrl(
                            room.selectedProducts.profile.fileInfos[0].url
                          )}
                        />
                      )}
                    </>
                  )}
                </div>

                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <td>{t("Name")}</td>
                      <td>{room.selectedProducts.profile.name}</td>
                    </tr>
                    <tr>
                      <td>{t("Art. Nr.")}</td>
                      <td>{room.selectedProducts.profile.sku}</td>
                    </tr>
                    <tr>
                      <td>{t("Quantity")}</td>
                      <td>{room.selectedProducts.profilesQuantity}</td>
                    </tr>
                    <tr>
                      <td>{t("Double beam")}</td>
                      <td>
                        {room.selectedProducts.profile.double && (
                          <>{t("Yes")}</>
                        )}
                        {!room.selectedProducts.profile.double && (
                          <>{t("No")}</>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Single length")}</td>
                      <td>
                        {(calculateRoomLength(room) / 1000).toLocaleString(
                          i18next.language
                        )}{" "}
                        m
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total length")}</td>
                      <td>
                        {(
                          (calculateRoomLength(room) *
                            room.selectedProducts.profilesQuantity) /
                          1000
                        ).toLocaleString(i18next.language)}{" "}
                        m
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {room.selectedProducts?.wallProfile && (
                <div>
                  <div className={styles.page__beams__image}>
                    {room.selectedProducts.wallProfile?.fileInfos ===
                      undefined && (
                        <img alt="" className={styles.image} src={noImage} />
                      )}
                    {room.selectedProducts.wallProfile?.fileInfos !==
                      undefined && (
                        <>
                          {room.selectedProducts.wallProfile.fileInfos.length >
                            0 && (
                              <img
                                alt=""
                                className={styles.image}
                                src={UtilsHelper.createFileUrl(
                                  room.selectedProducts.wallProfile.fileInfos[0].url
                                )}
                              />
                            )}
                        </>
                      )}
                  </div>

                  <table className={styles.table}>
                    <tbody>
                      <tr>
                        <td>{t("Name")}</td>
                        <td>{room.selectedProducts.wallProfile.name}</td>
                      </tr>
                      <tr>
                        <td>{t("Art. Nr.")}</td>
                        <td>{room.selectedProducts.wallProfile.sku}</td>
                      </tr>
                      <tr>
                        <td>{t("Quantity")}</td>
                        <td>{room.selectedProducts.wallProfilesQuantity}</td>
                      </tr>
                      <tr>
                        <td>{t("Single length")}</td>
                        <td>
                          {room.selectedProducts.wallProfile.length / 1000} m
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Total length")}</td>
                        <td>
                          {(room.selectedProducts.wallProfile.length *
                            room.selectedProducts.wallProfilesQuantity) /
                            1000}{" "}
                          m
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {room.selectedProducts?.connectionAngle && (
                <div>
                  <div className={styles.page__beams__image}>
                    {room.selectedProducts.connectionAngle?.fileInfos ===
                      undefined && (
                        <img alt="" className={styles.image} src={noImage} />
                      )}
                    {room.selectedProducts.connectionAngle?.fileInfos !==
                      undefined && (
                        <>
                          {room.selectedProducts.connectionAngle.fileInfos
                            .length > 0 && (
                              <img
                                alt=""
                                className={styles.image}
                                src={UtilsHelper.createFileUrl(
                                  room.selectedProducts.connectionAngle.fileInfos[0]
                                    .url
                                )}
                              />
                            )}
                        </>
                      )}
                  </div>

                  <table className={styles.table}>
                    <tbody>
                      <tr>
                        <td>{t("Name")}</td>
                        <td>{room.selectedProducts.connectionAngle.name}</td>
                      </tr>
                      <tr>
                        <td>{t("SKU")}</td>
                        <td>{room.selectedProducts.connectionAngle.sku}</td>
                      </tr>
                      <tr>
                        <td>{t("Quantity")}</td>
                        <td>
                          {room.selectedProducts.connectionAgnlesQuantity}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={styles.page__beams}>
              {room.selectedProducts?.screwsQuantity > 0 && (
                <RoomOverviewItemComponent
                  {...{
                    data: room,
                    title: `SuperTeks ${t("screws")}`,
                    params: params?.rooms[index],
                    editUrl: null!,
                    visualisation: Screws1OverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              )}

              {room.selectedProducts?.connectionAgnlesScrewsQuantity > 0 && (
                <RoomOverviewItemComponent
                  {...{
                    data: room,
                    title: `Montageset ${t("screws")}`,
                    params: params?.rooms[index],
                    editUrl: null!,
                    visualisation: Screws2OverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              )}

              {room.selectedProducts?.antiTiltQuantity > 0 && (
                <RoomOverviewItemComponent
                  {...{
                    data: room,
                    title: t("Anti tilt angle"),
                    params: params?.rooms[index],
                    editUrl: null!,
                    visualisation: AntiTiltAngleOverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              )}
            </div>
            {/* end of room */}

            {/* footer */}
            <div className={styles.page__footer}>
              <a
                href="https://www.protektor.de/de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("www.protektor.de")}
              </a>
              <span>
                {t("Date:")} {currentDate()}
              </span>
            </div>
            {/* end of footer  */}
          </div>
        ))}
      </div>
    </>
  );
};

export default PrintOrderComponent;
