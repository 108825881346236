import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOrderApiService from "../../ApiService/orderApiService";
import useRoomApiService from "../../ApiService/roomApiService";
import { useCartStore } from "../../Stores/cart.store";
import ICheckout from "../../../interfaces/checkout";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import { t } from "i18next";

const InitCheckoutFormLogic = () => {
  let navigate = useNavigate();
  const { submit } = useOrderApiService();
  const setProjectsInCart = useCartStore((state) => state.setProjectsInCart);
  const { getInCartCount } = useRoomApiService();
  const { projectId } = useParams();
  const [isSubmited, setSubmited] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const options = [
    { text: t('Specification building material dealer'), value: 'withSpecification' },
    { text: t('Go on without specification building materials dealer'), value: 'withoutSpecification' }
  ]
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [data, setData] = useState<ICheckout>({
    materialDealer: "",
    address: "",
    city: "",
    contactPerson: "",
    houseNumber: "",
    postCode: "",
    withoutSpecification: false
  });

  useEffect(() => {
    setIsDisabled(!data.withoutSpecification && !(data.materialDealer !== ""
      && data.address !== ""
      && data.houseNumber !== ""
      && data.postCode !== ""
      && data.city !== ""
      && projectId !== ""))
  }, [data, projectId])

  useEffect(() => {
    getInCartCount().then((response) => {
      if (response.data < 1) {
        navigate("/projects?status=cart", { replace: true });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    let newData = dataHandlerHelper.handleInputChange(event, { ...data });
    setData(newData);
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isDisabled) return;

    data.projectId = Number(projectId);
    submit(data).then(() => {
      setSubmited(true);
      setProjectsInCart(0);
    });
  };


  useEffect(() => {
    let without = selectedOption.value !== "withSpecification";
    setData({ ...data, withoutSpecification:  without});
  }, [selectedOption]);

  return {
    data,
    isLoading,
    isSubmited,
    isDisabled,
    options,
    selectedOption,
    onChange,
    handleSubmit,
    setSelectedOption
  };
};

export default InitCheckoutFormLogic;
