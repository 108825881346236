import IPage from "../../../../interfaces/page"
import AdminOrdersComponent from "../../../Admin/Orders/AdminOrders.component"
import MainLayout from "../../../layouts/Main/Main.layout"



const AdminOrdersPage: React.FC<IPage> = () => {
    return <MainLayout component={AdminOrdersComponent} />
}

export default AdminOrdersPage