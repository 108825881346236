import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";
const Screws1OverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {

    return (
        <>
            <ProductOverviewComponent name="PROTEKTOR EDIFICIO Super TEKS-Schraube (6,3 x 19 mm SW 8)" calculationResult={undefined!} fileInfos={[{ url: params.superTeksAssetPath ?? "" }]} id={undefined!} />
        </>
    )
}

export default Screws1OverviewComponent;