import { useCallback, useEffect, useRef, useState } from "react";
import UtilsHelper from "../../../helpers/utils.helper";
import useSlideApiService from "../../ApiService/slideApiService";
import ISlide from "../../Interfaces/slide";

const InitPublicLayoutLogic = () => {
    const [height, setHeight] = useState<string>("300px");
    const [slides, setSlides] = useState<ISlide[]>([]);
    const {getSlideShow} = useSlideApiService()

    const request = useCallback(() => {
        getSlideShow().then((response) => {
            if (!response.data.empty) {
                setSlides(response.data.content);
            }
        });

        //eslint-disable-next-line
    }, [setSlides])

    const onResize = useCallback(() => {
        setHeight(`${UtilsHelper.getBodyHeight()}px`)

        //eslint-disable-next-line
    }, [setHeight])

    useEffect(() => {
        request();
        onResize();

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }

    }, [onResize, request]);


    return { height, slides }
}

export default InitPublicLayoutLogic;