import { t } from 'i18next';
import React from 'react';
import IModal from '../../Interfaces/modal';
import InitModalLogic from './Modal.logic';
import styles from './Modal.module.css';
import submitIcon from '../../../assets/images/wave3/arrow-right.svg'
import ButtonShared from '../../SharedComponents/ButtonShared/ButtonShared';

const ModalComponent: React.FunctionComponent<IModal> = (modal: IModal) => {

    const { isLoading, confirm } = InitModalLogic(modal);

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                {modal.image !== null && <img className={styles.modal__image} src={modal.image} alt={modal.message} />}

                <h2 className={styles.modal__title}>{modal.title}</h2>
                <p className={styles.modal__text}>{modal.message}</p>

                <ButtonShared disabled={isLoading} onClick={() => {confirm()}} text={modal.confirmText} img_src={submitIcon} type="button"/>
                <div className={styles.divider}></div>
                {modal.showCancel && <button className={styles.cancel__button} onClick={() => { modal.cancelAction() }}>{t('Cancel')}</button>}
            </div>
        </div>
    )
}

export default ModalComponent;