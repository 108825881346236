import React from "react";
import IGridProduct from "../../../Interfaces/gridProduct";
import IStep from "../../../Interfaces/Step";
import ProductComponent from "../Product/Product.component";
import InitSPStep2Logic from "./SPStep2.logic";
import styles from "../SelectProduct.module.css";
import ISelectedProductsRoom from "../../../Interfaces/roomWithSelectedProducts";
import { ProductType } from "../../../enums/productType";
import SwitchButton from "../../../SharedComponents/SwitchButton/SwitchButton";
import { t } from "i18next";

const SPStep2Component: React.FunctionComponent<IStep<ISelectedProductsRoom>> = (
  params: IStep<ISelectedProductsRoom>
) => {
  const { isWPActive, isCAActive, selectWP, selectCA, changeShowed, getActive } =
    InitSPStep2Logic(params);

  return (
    <>
      <SwitchButton
        onClick={changeShowed}
        default={getActive()}
        leftButtonText={t("Wall connection profile")}
        rightButtonText={t("Connection angles")}
        argumentLeft="profiles"
        argumentRight="angles"
      />

      <div className={styles.products}>
        {getActive() === "left" && params.data.selectedProducts.profile?.wallProfiles?.map((product, index) => (
          <div
            onClick={() => {
              selectWP(product);
            }}
            className={`${styles.product} ${
              isWPActive(product) ? styles.active : ""
            }`}
            key={index}
          >
            <ProductComponent
              {...({
                ...product,
                selected: isWPActive(product),
                type: ProductType.connection,
              } as IGridProduct)}
            />
          </div>
        ))
        }

        {getActive() === "right" && params.data.selectedProducts.profile?.connectionAngles?.map((product, index) => (
          <div
            onClick={() => {
              selectCA(product);
            }}
            className={`${styles.product} ${
              isCAActive(product) ? styles.active : ""
            }`}
            key={index}
          >
            <ProductComponent
              {...({
                ...product,
                selected: isCAActive(product),
                type: ProductType.connection,
              } as IGridProduct)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SPStep2Component;
