import { TokenResponse } from "@react-oauth/google";
import i18next, { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import IFullUser from "../../interfaces/fullUser";
import IPassword from "../../interfaces/password";
import IUser from "../../interfaces/user";
import tokenHelper from "../helpers/tokenHelper";
import IResetPassword from "../../interfaces/resetPassword";
import IAuthentificationUser from "../Interfaces/IAuthentificationUser";
import { clientInstance } from "./clientInstance";
import ILanguage from "../../interfaces/language";

const useUserApiService = () => {
  const client = clientInstance();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const registerUser = (formData: FormData, captcha: string) =>
    client.post(`/user/register`, formData, {
      params: { captcha, lang: i18next.language },
    });

  const formLogin = (rememberMe: boolean, data: IUser) =>
    client.post(`/user/login/${rememberMe}`, data);

  const getExternalUser = () => client.get(`/user/external`);

  const loadUserProfile = () => client.get<IFullUser>(`/user/profile`);

  const getUserProfile = () =>
    client.get<IAuthentificationUser>(`/user/profile`);

  const updatePasswordForGoogleUser = (data: IPassword, external: boolean) =>
    client.post(`/user/update/password${external ? "/google" : ""}`, data);

  const verifyCode = () =>
    client.get(`/user/verify/${searchParams.get("code")}`);

  const reactivateVerificationCode = () =>
    client.post<any>(`/user/reactivate`, null);

  const sendPasswordRecoveryLink = (data: IUser) =>
    client.post(`/user/reset/password`, data);

  const resetPassword = (data: IResetPassword) =>
    client.post(`/user/reset/password/verify`, data);

  const updateUserProfile = (formData: FormData) =>
    client.post(`/user/update`, formData);

  const changeUserLanguage = (lang: ILanguage) =>
    client.post(`/user/lang`, lang);

  const successfulGoogleLogin = (
    token: TokenResponse,
    rememberMe: boolean,
    setDisableLogin: Function,
    setErrorMessageTranslationKey: Function
  ) => {
    setErrorMessageTranslationKey("");
    setDisableLogin(true);

    client
      .get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
        params: { access_token: token.access_token, allowErrorModal: false },
      })
      .then((response) => {
        client
          .post(`/user/login/google/${rememberMe}`, response.data, {
            params: { lang: i18next.language },
          })
          .then((response) => {
            tokenHelper.storeToken(response.data.token);
            navigate("/dashboard");
          })
          .catch((error) => {
            setDisableLogin(false);
            if (error.response?.data?.translatableCode) {
              setErrorMessageTranslationKey(
                t(error.response.data.translatableCode, { ns: "codes" })
              );
            }
            else {
              setErrorMessageTranslationKey(
                t("Error, service unavailable. Please try again later.")
              );
            }
          });
      })
      .catch((error) => {
        setDisableLogin(false);
        setErrorMessageTranslationKey(
          "Error, service unavailable. Please try again later."
        );
      });
  };

  return {
    registerUser,
    formLogin,
    loadUserProfile,
    successfulGoogleLogin,
    updatePasswordForGoogleUser,
    verifyCode,
    reactivateVerificationCode,
    sendPasswordRecoveryLink,
    resetPassword,
    getExternalUser,
    changeUserLanguage,
    getUserProfile,
    updateUserProfile,
  };
};

export default useUserApiService;
