import { t } from "i18next";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import InputShared from "../../SharedComponents/InputShared/InputShared";
import InitCheckoutFormLogic from "./CheckoutForm.logic";
import styles from "./CheckoutForm.module.css";
import submittedIcon from "../../../assets/images/wave3/submitted.svg";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "../Loading/Loading.component";
import SelectComponent from "../Select/Select.component";
import ISelectOption from "../../Interfaces/selectOption";

const CheckoutFormComponent: React.FunctionComponent = () => {
  const { data,
    isSubmited,
    isLoading,
    isDisabled,
    options,
    selectedOption,
    setSelectedOption,
    onChange,
    handleSubmit } = InitCheckoutFormLogic();
  return (
    <>
      {isLoading && <LoadingComponent></LoadingComponent>}
      {!isLoading && (
        <>
          {!isSubmited && (
            <>
              <form className={styles.form} onSubmit={handleSubmit}>
                <h2 className={styles.title}>{t("Checkout")}</h2>
                <p className={styles.description}>
                  <span>*</span> {t("indicates a required field")}
                </p>


                <div className={styles.select_container}>
                  <SelectComponent options={options}
                    selected={selectedOption}
                    defaultText={""}
                    onChange={(e: ISelectOption) => { setSelectedOption(e) }} />
                </div>


                {!data.withoutSpecification && <>
                  <InputShared
                    name="materialDealer"
                    labelText={t("Name")}
                    required={true}
                    onChange={onChange}
                    type="text"
                    value={data.materialDealer}
                    placeholder={t("Name of the material dealer...")}
                    mode="settings"
                  />
                  <InputShared
                    name="address"
                    labelText={t("Address")}
                    required={true}
                    onChange={onChange}
                    type="text"
                    value={data.address}
                    placeholder={t("Address of the material dealer...")}
                    mode="settings"
                  />
                  <InputShared
                    name="houseNumber"
                    labelText={t("House number")}
                    required={true}
                    onChange={onChange}
                    type="text"
                    value={data.houseNumber}
                    placeholder={t("House number of the material dealer...")}
                    mode="settings"
                  />
                  <InputShared
                    name="city"
                    labelText={t("City")}
                    required={true}
                    onChange={onChange}
                    type="text"
                    value={data.city}
                    placeholder={t("City of the material dealer...")}
                    mode="settings"
                  />
                  <InputShared
                    name="postCode"
                    labelText={t("Postcode")}
                    required={true}
                    onChange={onChange}
                    type="text"
                    value={data.postCode}
                    placeholder={t("Postcode of the material dealer...")}
                    mode="settings"
                  />
                  <InputShared
                    name="contactPerson"
                    labelText={t("Contact person")}
                    required={false}
                    onChange={onChange}
                    type="text"
                    value={data.contactPerson}
                    placeholder={t("Material dealer contact person...")}
                    mode="settings"
                  />
                </>}
                <ButtonShared disabled={isDisabled} type="submit" text={t("Submit")} />
              </form>
            </>
          )}

          {isSubmited && (
            <>
              <div className={styles.submitted__icon__container}>
                <img src={submittedIcon} alt="" />
              </div>
              <h2 className={styles.submitted__title}>
                {t("Projects successfully submited")}
              </h2>
              <p className={styles.submitted__description}>
                {t(
                  "Thank you for submiting an order with Protektor App. Now relax, until you recieve full offer from us within the next 24 hours."
                )}
              </p>

              <Link className={styles.submitted__link} to={"/dashboard"}>
                <ButtonShared
                  type="button"
                  img_side={"left"}
                  img_fa={faChevronLeft}
                  text={t("Back to dashboard")}
                />
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutFormComponent;
