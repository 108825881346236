import NotificationModalComponent from "../NotificationModal/NotificationModal.component";
import InitVerificationModalLogic from "./VerificationModal.logic";

const VerificationModalComponent: React.FC<any> = () => {
    const { modal, showModal } = InitVerificationModalLogic();
    return (<>
        {showModal && <NotificationModalComponent {...modal} />}
    </>
    )
}
export default VerificationModalComponent;