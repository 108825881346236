import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import heroImage from '../../../assets/images/no-projects.svg';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import Pagination from '../../../Wave3/Components/Table/Pagination.component';
import Table from '../../../Wave3/Components/Table/Table.component';
import IModal from '../../../Wave3/Interfaces/modal';
import IPage from '../../../interfaces/page';
import IProfileView from '../../../interfaces/profileView';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import useProfileApiService from '../../../Wave3/ApiService/profileApiService';


const ProfilesPage: React.FunctionComponent<IPage> = props => {
    const [profiles, setProfiles] = useState<IProfileView[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [count, setCount] = useState<number>(0);

    const [idForDelete, setIdForDelete] = useState<number>(null!);
    
    const columns = ["name", "sku", "price", "lyEffCm4", "lyFicCm4", "wyEffCm3", "strapWidth", "fyk.value", "isDouble", "profileType"];
    const headers = [t("Name"), t("SKU"), t("Price"), t("lyEffCm4"), t("lyFicCm4"), t("wyEffCm3"), t("Strap width"), t("Fyk"), t("Is double"), t("Type")];
    const {getProfilesByPage, deleteProfileRequest} = useProfileApiService()

    const request = useCallback((page: number = 0, rowsPerPage: number = 5) => {
        getProfilesByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setProfiles(response.data.content);
                setCount(response.data.totalElements);
            }
            setLoading(false);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        request();
    }, [request])

    const deleteProfile = (id: number) => {
        return new Promise((resolve: any, reject: any) => {
            deleteProfileRequest(id).then(
                (response) => {
                    resolve();
                    window.location.reload();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteProfile(idForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete profile"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = (id: number) => {
        setShowDeleteModal(true);
        setIdForDelete(id);
    }

    const editPath = "/profiles/edit/";
    const newPath = "/profiles/create/";

    return (
        <>
                        <NavigationComponent />

        <div className={globalStyles.layout}>
        <Fragment>
            {!isLoading && profiles?.length < 1 &&
                <div >
                    <div >
                        <img src={heroImage} alt="start building" />
                        <p>{t('There are no profiles')}</p>
                    </div>
                </div>
            }
            {isLoading && <LoadingComponent></LoadingComponent>}
            {!isLoading && profiles?.length > 0 &&
                <div className={globalStyles.layout}>
                    <Table rows={profiles} columns={columns} headers={headers} new={newPath} edit={editPath} delete={deleteFunction} />
                    <Pagination
                        count={count}
                        rowsPerPage={5}
                        request={request}
                    />
                </div>
            }

            {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
        </Fragment>
        </div>
        </>
    )
}

export default ProfilesPage;