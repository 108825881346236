import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import Pagination from '../../../Wave3/Components/Table/Pagination.component';
import Table from '../../../Wave3/Components/Table/Table.component';
import IConnectionAngle from '../../../interfaces/connectionAngle';
import IModal from '../../../Wave3/Interfaces/modal';
import IPage from '../../../interfaces/page';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import useConnectionAngleApiService from '../../../Wave3/ApiService/connectionAngleApiService';

const ConnectionAnglesPage: React.FunctionComponent<IPage> = props => {
    const [connectionAngles, setConnectionAngles] = useState<IConnectionAngle[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);


    const [count, setCount] = useState<number>(0);

    const [idForDelete, setIdForDelete] = useState<number>(null!);
    
    const columns = ["name", "sku", "price", "fyrd"];
    const headers = [t("Name"), t("SKU"), t("Price"), t("fyrd")];

    const {getConnectionAnglesByPage, deleteConnectionAngleRequest} = useConnectionAngleApiService()

    const request = useCallback((page: number = 0, rowsPerPage: number = 5) => {
        getConnectionAnglesByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setConnectionAngles(response.data.content);
                setCount(response.data.totalElements);
            }
            setLoading(false);
        });
    }, [getConnectionAnglesByPage])

    useEffect(() => {
        request();
        //eslint-disable-next-line
    }, [])

    const deleteConnectionAngle = (id: number) => {
        return new Promise((resolve: any, reject: any) => {
            deleteConnectionAngleRequest(id).then(
                (response) => {
                    resolve();
                    window.location.reload();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteConnectionAngle(idForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete connection angle"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = (id: number) => {
        setShowDeleteModal(true);
        setIdForDelete(id);
    }

    const editPath = "/connection-angles/edit/";
    const newPath = "/connection-angles/create/";

    return (<>
                        <NavigationComponent />

        <div className={globalStyles.layout}>
            <Fragment>
                {!isLoading && connectionAngles?.length < 1 &&
                    <div>
                        <div >
                            <p>{t('There is no connection angles')}</p>
                        </div>
                    </div>
                }
                {isLoading && <LoadingComponent></LoadingComponent>}
                {!isLoading && connectionAngles?.length > 0 &&
                    <div className={globalStyles.layout}>
                        <Table rows={connectionAngles} columns={columns} headers={headers} new={newPath} edit={editPath} delete={deleteFunction} />
                        <Pagination
                            count={count}
                            rowsPerPage={5}
                            request={request}
                        />
                    </div>
                }

                {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
            </Fragment></div></>
    )
}

export default ConnectionAnglesPage;