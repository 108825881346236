import Config from "../config/config";

const getBodyHeight = () => {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
};

const createFileUrl = (url: string) => {
  return `${Config.defaults.apiUrl}/files${url}` 
};

const formatDate = (date: Date) => {
  let nd = new Date(date);
  let day: any = nd.getDate() + 1;
  if (day < 10) {
    day = "0" + day;
  }
  let month: any = nd.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let minutes: any = nd.getMinutes() + 1;
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let seconds: any = nd.getSeconds() + 1;
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${day}.${month}.${nd.getFullYear()}. ${nd.getHours()}:${minutes}:${seconds}`;
};

const UtilsHelper = {
  getBodyHeight,
  createFileUrl,
  formatDate,
};

export default UtilsHelper;
