import { useEffect, useState } from "react";
import { DeflectionLimit } from "../../../../enums/deflectionLimit";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";

const InitCRStep4Logic = (params: IStep<IRoomFormData>) => {
    const {updateRoom} = useRoomApiService()
    const { resetBackButtonToDashboardState} = useBackButtonHelper()
    useEffect(() => {
        disableNext();
        // if (searchParams.get('mode') === 'edit') {
        //     params.options?.setBackText(t("Or back to project overview"))
        // }
        // else if (searchParams.get('mode') === 'redirection') {
        //     params.options?.setBackText(t("Or back to projects"))
        // }
        // else {
        //     params.options?.setBackText(t("Or back to project"))
        // }
        // eslint-disable-next-line 
        if(params.setShouldSetBackButton) params.setShouldSetBackButton(false)
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(true)
    
    useEffect(() => {
        let newData = {...params.data,nextStep: (params.data.nextStep > 4 ? params.data.nextStep : 4)}
        updateRoom(newData, setIsLoading, params.options.setAllowSkipTo, params.setData);

        // eslint-disable-next-line 
    }, [])
    
    const disableNext = () => params.setDisableNext(params.data.deflectionLimitString === DeflectionLimit.OTHER ? params.data.deflectionLimit < 0.1 : false, 3);

    useEffect(() => {
        disableNext()
        //eslint-disable-next-line
    },  [params.data.deflectionLimit])

    const handleDeflectionLimitStringValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData: IRoomFormData = { ...params.data };
        newData.deflectionLimitString = event.currentTarget.value;
        if (event.currentTarget.value === DeflectionLimit.l300)
            newData.deflectionLimit = dataHandlerHelper.calculateDeflectionLimit(params.data, 300);
        else if (event.currentTarget.value === DeflectionLimit.l500)
            newData.deflectionLimit = dataHandlerHelper.calculateDeflectionLimit(params.data, 500);
        else
            newData.deflectionLimit = 0;
            
        params.options.setAllowSkipTo(4)
        params.setData({...newData, nextStep: 4});
        if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        if(Number(event.currentTarget.value) > 1000000000) event.currentTarget.value = '1000000000'
        if(Number(event.currentTarget.value) < 0) event.currentTarget.value = '0'

        let newData: IRoomFormData = dataHandlerHelper.handleInputChange(event, params.data);
        newData.nextStep = 4
        params.setData({...newData, nextStep: 4})
        if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
    }
    return { onChange, handleDeflectionLimitStringValueChange, isLoading };
}


export default InitCRStep4Logic;