import IRoomFormData from "../../../../Interfaces/roomFormData";
import s2Length from '../../../../../assets/images/wave3/visualization/length-default.svg';
import s2Width from '../../../../../assets/images/wave3/visualization/width-default.svg';

import styles from './CRStep2Visualization.module.css'
import { SpanDirection } from "../../../../../enums/spanDirection";
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";
import CRStep2VisualizationLogic from "./CRStep2Visualization.logic";

const CRStep2VisualizationComponent: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    const {getLengthImage, getWidthImage} = CRStep2VisualizationLogic(params);
   
    return (
        <div className={styles.container}>
            {params.spanDirection === SpanDirection.ROOM_LENGTH && <img className={styles.room__image} src={s2Length} alt="" />}
            {params.spanDirection === SpanDirection.ROOM_WIDTH && <img className={styles.room__image} src={s2Width} alt="" />}

            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>

            {params.spanDirection === SpanDirection.ROOM_LENGTH &&
                <img src={getLengthImage()} className={styles.room__length_direction} alt="lenght direction" />}

            {params.spanDirection === SpanDirection.ROOM_WIDTH &&
                <img src={getWidthImage()} className={styles.room__width_direction}  alt="width direction"/>}

            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>

        </div>
    )
}

export default CRStep2VisualizationComponent;