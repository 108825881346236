import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import Pagination from '../../../Wave3/Components/Table/Pagination.component';
import Table from '../../../Wave3/Components/Table/Table.component';
import IModal from '../../../Wave3/Interfaces/modal';
import IPage from '../../../interfaces/page';
import IWallProfile from '../../../interfaces/wallProfile';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import useWallProfileApiService from '../../../Wave3/ApiService/wallProfileApiService';


const WallProfilesPage: React.FunctionComponent<IPage> = props => {
    const [wallProfiles, setWallProfiles] = useState<IWallProfile[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [count, setCount] = useState<number>(0);
    
    const [idForDelete, setIdForDelete] = useState<number>(null!);

    const columns = ["name", "sku", "price", "depth", "fyk", "materialThickness", "profileType", "length"];
    const headers = [t("Name"), t("SKU"), t("Price"), t("Depth"), t("Fyk"), t("Material thickness"), t("Type"), t("Length")];
    const {getWallProfilesByPage, deleteWallProfileRequest} = useWallProfileApiService()

    const request = useCallback((page: number = 0, rowsPerPage: number = 5) => {
        getWallProfilesByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setWallProfiles(response.data.content);
                setCount(response.data.totalElements);
            }
            setLoading(false);
        });

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        request();
    }, [request])

    const deleteWallProfile = (id: number) => {
        return new Promise((resolve: any, reject: any) => {
            deleteWallProfileRequest(id).then(
                (response) => {
                    resolve();
                    window.location.reload();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteWallProfile(idForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete wall profile"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = (id: number) => {
        setShowDeleteModal(true);
        setIdForDelete(id);
    }

    const editPath = "/wall-profiles/edit/";
    const newPath = "/wall-profiles/create/";

    return (        <>
                        <NavigationComponent />

        <div className={globalStyles.layout}>
        <Fragment>
            {!isLoading && wallProfiles?.length < 1 &&
                <div>
                    <div >
                        <p>{t('There is no wall profiles')}</p>
                    </div>
                </div>
            }
            {isLoading && <LoadingComponent></LoadingComponent>}
            {!isLoading && wallProfiles?.length > 0 &&
                <div className={globalStyles.layout}>
                    <Table rows={wallProfiles} columns={columns} headers={headers} new={newPath} edit={editPath} delete={deleteFunction} />
                    <Pagination
                        count={count}
                        rowsPerPage={5}
                        request={request}
                    />
                </div>
            }

            {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
        </Fragment>
        </div>
        </>
    )
}

export default WallProfilesPage;