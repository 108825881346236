import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useBackButtonHelper from "../../../helpers/backButtonHelper";
import IStepControls from "../../../Interfaces/StepControls";
import styles from "./StepControls.module.css";
const InitStepControlsLogic = (params: IStepControls) => {
  const { peekBackUrl, popBackUrl } = useBackButtonHelper();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const goTo = (index: number) => {
    if (
      index < params.allowSkipTo ||
      (!params.disableNext && index === params.currentStep)
    )
      params.setCurrentStep(index + 1);
  };
  const [backUrl, setBackUrl] = useState<string>();
  const [backTranslationKey, setBackTranslationKey] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const { backUrl, backTranslationKey } = peekBackUrl();
    setBackTranslationKey(backTranslationKey);
    setBackUrl(backUrl);
    if (searchParams.get("mode") === "edit") setIsInEditMode(true);
  }, []);

  const getIndicatorClass = (index: number) => {
    return index + 1 === params.currentStep
      ? `${styles.step__indicator} ${styles.step__indicator__active}`
      : index < params.allowSkipTo ||
        (!params.disableNext && index === params.currentStep)
      ? styles.step__indicator
      : styles.step__indicator__disabled;
  };

  const backButtonClick = () => {
    const { backUrl } = popBackUrl();
    navigate(String(backUrl ?? "/dashboard"), { replace: true });
  };

  useEffect(() => {
    if (params.shouldSetBackButton) {
      const { backUrl, backTranslationKey } = peekBackUrl();
      setBackTranslationKey(backTranslationKey);
      setBackUrl(backUrl);
    }
  }, [params.shouldSetBackButton]);

  return {
    goTo,
    getIndicatorClass,
    backUrl,
    backTranslationKey,
    popBackUrl,
    backButtonClick,
    navigate,
    isInEditMode,
  };
};

export default InitStepControlsLogic;
