import { useNavigate } from "react-router-dom"
import IOrder from "../../../Interfaces/order";

const InitAdminOrdersItemLogic = (order: IOrder) => {
    let navigate = useNavigate();

    const handleClick = () =>{
        navigate(`/orders/${order.id}`)
    }
    return { handleClick }
}

export default InitAdminOrdersItemLogic;