import { useEffect, useState } from "react";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";

const InitCRStep3Logic = (params: IStep<IRoomFormData>) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const {updateRoom} = useRoomApiService()
    const { resetBackButtonToDashboardState} = useBackButtonHelper()
    useEffect(() => {
        disableNext();
        // if (searchParams.get('mode') === 'edit') {
        //     params.options?.setBackText(t("Or back to project overview"))
        // }
        // else if (searchParams.get('mode') === 'redirection') {
        //     params.options?.setBackText(t("Or back to projects"))
        // }
        // else {
        //     params.options?.setBackText(t("Or back to project"))
        // }
        
        let newData = {...params.data, nextStep: (params.data.nextStep > 3 ? params.data.nextStep : 3)}
        updateRoom(newData, setIsLoading, params.options.setAllowSkipTo, params.setData);
        if(params.setShouldSetBackButton)params.setShouldSetBackButton(false)
        // eslint-disable-next-line 
    }, []);
    
    const disableNext = () => params.setDisableNext(params.data.centerDistance < 250 || params.data.centerDistance > 1500, 2);
    useEffect(() => {
        disableNext()
        // eslint-disable-next-line 
    }, [params.data.centerDistance])


    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        if(Number(event.currentTarget.value) > 1500) event.currentTarget.value = '1500'
        if(Number(event.currentTarget.value) > 1000000000) event.currentTarget.value = '1000000000'
        if(event.currentTarget.value.length > 3 && Number(event.currentTarget.value) < 250) event.currentTarget.value = '250'
        if(Number(event.currentTarget.value) < 0) event.currentTarget.value = '0'

        let newData = dataHandlerHelper.handleInputChange(event, { ...params.data });
        params.options.setAllowSkipTo(3)
        params.setData({...newData, nextStep: 3});
        if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
    }
    return { onChange, isLoading };
}


export default InitCRStep3Logic;