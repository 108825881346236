import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const ConnectionOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            {params.connectionProductType === "CA" && <>
                <ProductOverviewComponent {...params.selectedProducts.connectionAngle} />
            </>}
            {params.connectionProductType === "WP" && <>
                <ProductOverviewComponent {...params.selectedProducts.wallProfile} />
            </>}
        </>
    )
}

export default ConnectionOverviewComponent;