import axios from "axios";
import Config from "../../config/config";
import tokenHelper from "../helpers/tokenHelper";

const clientInstance = () => axios.create(
  {
    baseURL: Config.defaults.apiUrl,
    headers: {
      "Authorization": tokenHelper.getToken()
    }
  }
);

export { clientInstance };