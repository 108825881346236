import React from 'react'
import styles from './EmailAlert.module.css'

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import InitEmailAlertLogic from './EmailAlert.logic'

const EmailAlertComponent: React.FunctionComponent = () => {

    const { message, isLoading, resend } = InitEmailAlertLogic();

    return (
        <div className={styles.alert}>
            <div className={styles.content}>
                <div>
                    {message}
                </div>
                <button disabled={isLoading} onClick={resend} className={styles.resend__button}>
                    {isLoading && <FontAwesomeIcon className={styles.spinner} icon={faSpinner}></FontAwesomeIcon>}
                    {t("Resend")}
                </button>
            </div>
        </div>
    )
}

export default EmailAlertComponent;