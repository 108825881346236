import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import IConfiguration from "../../../interfaces/configuration"
import useConfigurationApiService from "../../../Wave3/ApiService/configurationApiService"

const InitCreateConfigurationLogic = () => {
    const {createConfiguration, getConfigurationByKey, updateConfiguration} = useConfigurationApiService()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [formValue, setFormValue] = useState<IConfiguration>({
        id: 0,
        configurationKey: "",
        value: ""
    })
    const {configurationKey} = useParams()
    const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        if(configurationKey){
            getConfigurationByKey(configurationKey).then(response => {
                setFormValue(response.data)
                setIsLoading(false)
            }).catch(error => {
                console.log(error)
            })
        }else{
            setIsLoading(false)
        }
        //eslint-disable-next-line
    }, [configurationKey])

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        setFormValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault()

        if(configurationKey){
            updateConfiguration(formValue).then(response => {
                navigate("/configuration")
            }).catch(error => {
                console.log(error)
            })
        }else{
        createConfiguration(formValue).then(response => {
            navigate("/configuration")
        }).catch(error => {
            console.log(error)
        })}
    }

    return {formValue, handleChange, handleSubmit, showSelectionModal, setShowSelectionModal, setFormValue, isLoading}
}

export default InitCreateConfigurationLogic