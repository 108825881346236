import { useState } from "react";
import ISwitchButton from "../../Interfaces/switchButton";


const InitSwitchButtonLogic = (props: ISwitchButton) => {
    const [activeButton, setActiveButton] = useState(props.default);
    const activateButton = (activeBtn: string) => {
      if (activeBtn === "left") {
        setActiveButton("left");
      } else{
          setActiveButton("right")
      }
    };



    return {activeButton, activateButton}
}

export default InitSwitchButtonLogic