import { useNavigate } from "react-router-dom";
import IProjectGrid from "../../../Interfaces/projectGrid";
import IProjectItem from "../../../Interfaces/projectItem";
import ProjectItemComponent from "../ProjectItem/ProjectItem.component";
import styles from "./ProjectsGrid.module.css";
import plusIcon from "../../../../assets/images/wave3/add-room.svg";
import { RoomStatus } from "../../../../enums/roomStatus";
import { useEffect } from "react";
import InitProjectsGridComponentLogic from "./ProjectsGridComponent.logic";
import { t } from "i18next";

const ProjectsGridComponent: React.FC<IProjectGrid> = (props: IProjectGrid) => {
  const { searchParams, navigate } = InitProjectsGridComponentLogic(props);
  return (
    <>
      <div className={styles.projects}>
        {searchParams.get("status") !== "trash" && (
          <div
            className={styles.new__project}
            onClick={() => navigate("/room")}
          >
            <img
              src={plusIcon}
              alt="plus icon"
              className={styles.plus__button}
            />
          </div>
        )}
        {(props.projects?.length === 0 || props.projects === undefined) &&
          searchParams.get("status") === "trash" && (
            <div>
              <h2>{t("Trash is empty")}</h2>
            </div>
          )}
        {props.projects?.map((project, index) => (
          <ProjectItemComponent
            key={index}
            item={project as unknown as IProjectItem}
            updateItem={props.updateItem}
            deleteItem={props.deleteItemAtIndex}
            status={props.status}
          />
        ))}
      </div>
    </>
  );
};

export default ProjectsGridComponent;
