import { useEffect, useRef, useState } from "react"
import IFileInfo from "../../../interfaces/fileInfo";
import useFileApiService from "../../ApiService/fileApiService";
import UtilsHelper from "../../../helpers/utils.helper";


const InitFilesLogic = () => {
    const [files, setFiles] = useState<IFileInfo[]>([]);
    const { uploadFiles, deleteFile, getFiles } = useFileApiService()

    useEffect(() => {
        loadFiles()
    }, []);

    const [selectedFiles, setSelectedFiles] = useState<FileList>(null!);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(e.target.files!);
    };

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedFiles === null) {
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i])
        }
        uploadFiles(formData).then(() => {
            loadFiles()
        }).catch((e) => { alert(e) })
    }

    const loadFiles = () => {
        getFiles().then((res) => { setFiles(res.data) }).catch(e => alert(e.error))
    }

    const handleDelete = (file: IFileInfo) => {
        deleteFile(file).then(r => { loadFiles() }).catch(e => alert(e.error));
    }

    const copyUrl = (file: IFileInfo) => {
        navigator.clipboard.writeText(UtilsHelper.createFileUrl(file.url))
            .then(() => {
               alert(`URL of "${file.name?.replace(/^[^_]*_/, '')}"\nsuccessfully copied to clipboard`);
            })
            .catch((err) => {
                alert('Unable to copy URL to clipboard \n'+err);
            });
    }

    return {
        files,
        handleForm,
        handleFileChange,
        handleDelete,
        copyUrl
    }
}

export default InitFilesLogic;