import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IPage from '../../../interfaces/page';
import globalStyles from '../../../styles/Global.module.css';
import useSlideApiService from '../../../Wave3/ApiService/slideApiService';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import Pagination from '../../../Wave3/Components/Table/Pagination.component';
import Table from '../../../Wave3/Components/Table/Table.component';
import IModal from '../../../Wave3/Interfaces/modal';
import ISlide from '../../../Wave3/Interfaces/slide';


const SlidesPage: React.FunctionComponent<IPage> = props => {
    const [slides, setSlides] = useState<ISlide[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    let navigate = useNavigate();

    
    const [count, setCount] = useState<number>(0);

    const [idForDelete, setIdForDelete] = useState<number>(null!);

    const columns = ["title", "text", "theme", "url:img", "urlOnClick:a", "urlOnClickText", "orderNumber"];
    const headers = [t("Title"), t("Text"), t("Theme"), t("Image"), t("Link"), t("Link text"), t("Order")];
    const {getSlidesByPage, deleteSlideRequest} = useSlideApiService()

    const request = useCallback((page: number = 0, rowsPerPage: number = 5) => {
        getSlidesByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setSlides(response.data.content);
                setCount(response.data.totalElements);
                setLoading(false);
            }
            else{
                navigate(`/slides/create`);
            }
        });
        //eslint-disable-next-line
    }, [navigate])

    useEffect(() => {
        request();
    }, [request])

    const deleteSlide = (id: number) => {
        return new Promise((resolve: any, reject: any) => {
            deleteSlideRequest(id).then(
                (response) => {
                    resolve();
                    window.location.reload();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteSlide(idForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete slide"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = (id: number) => {
        setShowDeleteModal(true);
        setIdForDelete(id);
    }

    const editPath = "/slides/edit/";
    const newPath = "/slides/create/";

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <Fragment>
                    {!isLoading && slides?.length < 1 &&
                        <div>
                            <div>
                                <p>{t('There is no slides')}</p>
                                <Link to={`/slides/create`}><button type="button" className="button-secondary-link">{t("Add")}</button></Link>
                            </div>
                        </div>
                    }
                    {isLoading && <LoadingComponent></LoadingComponent>}
                    {!isLoading && slides?.length > 0 &&
                        <div className={globalStyles.layout}>
                            <Table rows={slides} columns={columns} headers={headers} new={newPath} edit={editPath} delete={deleteFunction} />
                            <Pagination
                                count={count}
                                rowsPerPage={5}
                                request={request}
                            />
                        </div>
                    }

                    {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
                </Fragment>
            </div></>
    )
}

export default SlidesPage;